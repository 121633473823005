import { Modifier, EditorState } from 'draft-js';
import { CUSTOM_BLOCK_NAME } from '../constants/constants';

export const insertCustomEntity = (editorState, entityType, id = undefined) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const contentStateWithEntity = contentState.createEntity(
    entityType,
    'IMMUTABLE',
    {
      id,
    }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  // add invisible space for desktop to able write text after entity
  const contentStateWithSpaceEntity = contentState.createEntity(
    CUSTOM_BLOCK_NAME.INVISIBLE_SPACE,
    'IMMUTABLE'
  );
  const spaceEntityKey = contentStateWithSpaceEntity.getLastCreatedEntityKey();

  const contentStateWithInvisibleSpace = Modifier.insertText(
    contentState,
    selectionState,
    String.fromCharCode(0xad),
    null,
    spaceEntityKey
  );

  const newContentStateWithAddedColon = Modifier.insertText(
    contentStateWithInvisibleSpace,
    selectionState,
    ' ',
    null,
    entityKey
  );

  return EditorState.push(
    editorState,
    newContentStateWithAddedColon,
    'insert-characters'
  );
};

export default function removeCustomEntity(editorState, id) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  let selectionStateToRemove = null;

  contentState.getBlocksAsArray().find((block) =>
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() === null) {
          return false;
        }
        const entity = contentState.getEntity(character.getEntity());
        const data = entity.getData() || {};

        return data.id === id;
      },
      (start, end) => {
        selectionStateToRemove = selectionState.merge({
          anchorOffset: start,
          focusOffset: end,
        });
      }
    )
  );

  if (!selectionStateToRemove) {
    return editorState;
  }

  const selectionStateAfterRemove = selectionStateToRemove.merge({
    focusOffset: selectionStateToRemove.getFocusOffset() - 1,
    anchorOffset: selectionStateToRemove.getFocusOffset() - 1,
  });

  const newContentState = Modifier.removeRange(
    contentState,
    selectionStateToRemove,
    'backward'
  );

  return EditorState.forceSelection(
    EditorState.push(editorState, newContentState),
    selectionStateAfterRemove
  );
}
