import React, { useState } from 'react';

import OutputToScreen from '../../assets/Output_ToScreen.svg';
import OutputToScreenActive from '../../assets/Output_ToScreen_Active.svg';
import StartRecordButton from '../../assets/Record_StartRecord.svg';
import ButtonHelpText from '../ButtonHelpText';
import RecordPanel from './RecordPanel';
import SaveRecordingModal from '../Modals/SaveRecordingModal';
import PresentationErrorModal from '../Modals/PresentationErrorModal';
import SelectMicrophoneModal from '../Modals/SelectMicrophoneModal';
import { t } from '../../i18n';

const Record = ({
  isPresentationSupported,
  isVideoRecordingSupported,
  startRecording,
  stopRecording,
  status,
  pauseRecording,
  resumeRecording,
  availability,
  onPresentationClick,
  onPresentationClose,
  isPresentationActive,
  pendingMediaBlobUrl,
  resetPendingMediaBlobUrl,
  presentationErrorText,
  clearPresentationErrorText,
  recSoundActive,
  setRecSoundActive,
  saveRecordingMimeType,
}) => {
  const [
    selectMicrophoneModalVisible,
    setSelectMicrophoneModalVisible,
  ] = useState(false);

  const hideSelectMicrophoneModal = () => {
    setSelectMicrophoneModalVisible(false);
  };
  const showSelectMicrophoneModal = () => {
    setSelectMicrophoneModalVisible(true);
  };

  return (
    <>
      <SelectMicrophoneModal
        show={selectMicrophoneModalVisible}
        onHide={hideSelectMicrophoneModal}
        startRecording={startRecording}
        recSoundActive={recSoundActive}
        setRecSoundActive={setRecSoundActive}
      />
      <SaveRecordingModal
        href={pendingMediaBlobUrl}
        show={!!pendingMediaBlobUrl}
        onHide={resetPendingMediaBlobUrl}
        saveRecordingMimeType={saveRecordingMimeType}
      />
      <PresentationErrorModal
        show={!!presentationErrorText}
        presentationErrorText={presentationErrorText}
        onHide={clearPresentationErrorText}
      />
      <div className="d-flex flex-nowrap justify-content-around">
        <div>
          <ButtonHelpText
            style={{
              opacity: availability ? 1 : 0.65,
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
            helpText={t(`send to\n2nd screen`)}
            disabled={!isPresentationSupported}
            onClick={() => {
              if (isPresentationActive) {
                return onPresentationClose();
              }
              onPresentationClick();
            }}
          >
            <img
              src={isPresentationActive ? OutputToScreenActive : OutputToScreen}
              alt={t('send to 2nd screen')}
              className="iconSize"
            />
          </ButtonHelpText>
        </div>
        <div style={{ maxHeight: '40px' }}>
          {status !== 'recording' ? (
            <ButtonHelpText
              variant="warning"
              className="btn-circle btn-sm btn-default"
              onClick={showSelectMicrophoneModal}
              helpText={t('record')}
              disabled={!isVideoRecordingSupported}
            >
              <img
                src={StartRecordButton}
                alt={t('record')}
                className="iconSize"
              />
            </ButtonHelpText>
          ) : (
            <RecordPanel
              status={status}
              pauseRecording={pauseRecording}
              stopRecording={stopRecording}
              resumeRecording={resumeRecording}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Record;
