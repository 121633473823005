import React from 'react';
import s from './Comment.module.scss';
import { preventAll } from '../../utils/prevent';
import CommentaryFieldIcon from '../../assets/CommentaryField.svg';

const Comment = ({ contentState, entityKey, onClick }) => {
  const entity = contentState.getEntity(entityKey);
  const { id } = entity.getData();

  return (
    <div
      onClick={(e) => preventAll(e)}
      data-id={id}
      className={s.root}
      contentEditable={false}
      readOnly
    >
      {/* <BoxArrowUpRight onClick={() => onClick(id)} className={s.icon} /> */}
      <img
        src={CommentaryFieldIcon}
        alt="C"
        onClick={() => onClick(id)}
        className={s.icon}
      />
    </div>
  );
};

export default Comment;
