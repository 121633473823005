import React, { useState } from 'react';
import FileButton from 'react-file-button';
import { t } from '../../i18n';
import NewFileConfirm from '../Modals/NewFileConfirm';
import newFileIcon from '../../assets/File_New.svg';
import fileSaveIcon from '../../assets/File_Save.svg';
import fileOpenIcon from '../../assets/File_Open.svg';
import style from './FileActions.module.scss';
import ButtonHelpText from '../ButtonHelpText';

const FileActions = ({
  clearWorkspace,
  insertClipboardText,
  exportToFile,
  importFromFile,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const confirmClearEditor = () => {
    clearWorkspace();
    handleClose();
  };

  return (
    <>
      <NewFileConfirm
        onHide={handleClose}
        confirmClearEditor={confirmClearEditor}
        exportToFile={exportToFile}
        show={show}
      />
      <div className="d-flex flex-nowrap justify-content-around">
        <div>
          <ButtonHelpText
            variant="warning"
            className="btn-circle btn-sm btn-default"
            helpText={t(`new\nfile`)}
            onMouseDown={(e) => {
              e.preventDefault();
              handleShow();
            }}
          >
            <img src={newFileIcon} alt={t('new file')} className="iconSize" />
          </ButtonHelpText>
        </div>
        <div>
          <ButtonHelpText
            variant="warning"
            className="btn-ellipse btn-sm btn-default"
            helpText={t(`insert from\nclipboard`)}
            helpTextWidth="100%"
            onMouseDown={(e) => {
              e.preventDefault();
              insertClipboardText();
            }}
          >
            {t('Insert').toUpperCase()}
          </ButtonHelpText>
        </div>
        <div>
          <div style={{ height: '40px' }}>
            <FileButton
              title=" "
              className={`${style.importButtonWrapper} ${style.fileButton}`}
              onChange={(e) => {
                e.preventDefault();
                if (e.target.files[0]) {
                  importFromFile(e.target.files[0]);
                }
              }}
              renderButton={() => {
                return (
                  <ButtonHelpText
                    variant="warning"
                    className="btn-circle btn-sm btn-default"
                    helpText={t(`open\nfile`)}
                  >
                    <img
                      src={fileOpenIcon}
                      alt={t('open file')}
                      className="iconSize"
                    />
                  </ButtonHelpText>
                );
              }}
            />
          </div>
        </div>
        <div>
          <ButtonHelpText
            variant="warning"
            className="btn-circle btn-sm btn-default"
            helpText={t(`save\nas`)}
            onMouseDown={(e) => {
              e.preventDefault();
              exportToFile();
            }}
          >
            <img src={fileSaveIcon} alt={t('Save file')} className="iconSize" />
          </ButtonHelpText>
        </div>
      </div>
    </>
  );
};

export default FileActions;
