import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { Button } from 'react-bootstrap';
import { useLongPress } from 'use-long-press';
import frameMoving from '../../assets/Frame_Moving.svg';
import deleteButton from '../../assets/Delete.svg';
import s from './TextField.module.scss';
import { useOnClickOutside } from '../../utils/useOnClickOutside';

const TextField = ({
  editorContainerRef,
  position,
  content,
  id,
  onChange,
  onRemove,
  color,
  fontSize,
  panelColor,
  setTextFieldFocus,
  focusEditor,
}) => {
  const [focus, setFocus] = useState(false);
  const field = useRef(null);
  const textArea = useRef(null);
  const panelColorRef = useRef(panelColor);
  const [height, setHeight] = useState(0);
  const longTap = useLongPress(() => {
    setFocus(true);
  });

  useEffect(() => {
    return () => {
      setTextFieldFocus(false);
    };
  }, []);

  const colorsPanel = document.getElementById('colorsPanel');
  const insertTextFieldIcon = document.getElementById('insertTextFieldIcon');

  useOnClickOutside(
    field,
    () => {
      focusEditor();
      setFocus(false);
      setTextFieldFocus(false);
    },
    [colorsPanel, insertTextFieldIcon]
  );

  // const anyClick = (e) => {
  //   const domNode = field;
  //   const eventNode = e.target;
  //   const colorsPanel = document.getElementById('colorsPanel');
  //   if (domNode.current === null) {
  //     window.removeEventListener('click', anyClick);
  //     return;
  //   }
  //   if (
  //     !domNode.current.contains(eventNode) &&
  //     !(eventNode.id === 'insertTextFieldIcon') &&
  //     !(eventNode.className === s.textField) &&
  //     !colorsPanel.contains(eventNode)
  //   ) {
  //     setFocus(false);
  //     setTextFieldFocus(false);
  //     window.removeEventListener('click', anyClick);
  //   }
  // };
  const handleDrag = (e, ui) => {
    onChange({ id, position: { left: ui.x, top: ui.y } });
  };
  const resizeArea = () => {
    if (textArea.current.scrollTop > 0) {
      textArea.current.style.height = `${textArea.current.scrollHeight}px`;
      setHeight(textArea.current ? textArea.current.scrollHeight - 35 : 45);
    }
  };
  const changeHeight = () => {
    setHeight(textArea.current.scrollHeight - 35);
  };
  useEffect(() => {
    if (
      textArea.current === document.activeElement &&
      panelColor !== panelColorRef.current
    ) {
      onChange({
        id,
        color: panelColor,
      });
      panelColorRef.current = panelColor;
    }
  }, [panelColor]);
  useLayoutEffect(() => {
    if (focus) {
      textArea.current.addEventListener('keyup', resizeArea);
      textArea.current.addEventListener('mouseup', changeHeight);
    } else {
      textArea.current.removeEventListener('keyup', resizeArea);
    }
  }, [focus]);

  return (
    <Draggable
      onStop={handleDrag}
      key={`${position.left}${position.top}`}
      defaultPosition={{ x: position.left, y: position.top }}
      handle={`img.${s.movingIcon}`}
      offsetParent={editorContainerRef.current}
    >
      <div ref={field} className={s.textFieldWrapper}>
        {focus && (
          <img
            draggable="false"
            src={frameMoving}
            alt=""
            className={`iconSize ${s.movingIcon}`}
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
        )}
        <textarea
          ref={textArea}
          autoFocus
          className={s.textField}
          style={{
            color,
            fontSize,
          }}
          name="TextField"
          id={id}
          cols="25"
          rows="4"
          value={content}
          onChange={(e) => {
            onChange({
              id,
              position,
              content: e.target.value,
            });
          }}
          onFocus={() => {
            setTextFieldFocus(true);
            // window.addEventListener('click', anyClick);
            setHeight(
              textArea.current ? textArea.current.scrollHeight - 35 : 45
            );
          }}
          onClick={() => {
            setTextFieldFocus(true);
          }}
          {...longTap}
        />
        {focus && (
          <Button
            onClick={() => {
              setTextFieldFocus(false);
              // window.removeEventListener('click', anyClick);
              onRemove(id);
            }}
            variant="warning"
            className={`btn-circle btn-sm btn-default ${s.deleteIcon}`}
            style={{
              top: `${height}px`,
            }}
          >
            <img
              src={deleteButton}
              draggable="false"
              alt=""
              className="iconSize"
            />
          </Button>
        )}
      </div>
    </Draggable>
  );
};

export default TextField;
