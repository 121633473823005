import React, { createContext, useContext } from 'react';
import { CircleFill } from 'react-bootstrap-icons';
import style from './RadioSelectorWrapper.module.scss';
import { t } from '../../../../i18n';

export const ActiveBulletContext = createContext(null);

const CircleButton = (props) => {
  const { index, label, onClick } = props;
  const activeIndex = useContext(ActiveBulletContext);

  const bulletSize = 10;
  const activeStyle = {
    color: '#D4822F',
    transform: 'scale(1.5)',
  };

  return (
    <div
      className="d-flex flex-nowrap justify-content-center align-items-center mb-1"
      onClick={() => onClick(index)}
    >
      <CircleFill
        style={index === activeIndex ? activeStyle : null}
        className={style.circle}
        size={bulletSize}
      />
      <div
        className={style.caption}
        style={{
          color: index === activeIndex ? '#D4822F' : 'inherit',
        }}
      >
        {label}
      </div>
    </div>
  );
};

const RadioSelector = (props) => {
  const { values, activeIndexAndValue, setActiveIndexAndValue } = props;

  const { index: activeIndex } = activeIndexAndValue;

  const handleCircleButtonClick = (clickedCircleIndex) => {
    setActiveIndexAndValue({
      index: clickedCircleIndex,
      value: values[clickedCircleIndex],
    });
  };

  return (
    <div
      className={`${style.radioSelector} d-flex flex-column flex-nowrap align-items-start`}
    >
      <ActiveBulletContext.Provider value={activeIndex}>
        <CircleButton
          index={0}
          onClick={handleCircleButtonClick}
          label={t('Deutsch')}
        />
        <CircleButton
          index={1}
          onClick={handleCircleButtonClick}
          label={t('English')}
        />
      </ActiveBulletContext.Provider>
    </div>
  );
};

const RadioSelectorWrapper = (props) => {
  const {
    icon,
    text,
    values,
    activeIndexAndValue,
    setActiveIndexAndValue,
  } = props;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-nowrap align-items-center">
        <div className="btn-circle btn-xs btn-default">
          <img src={icon} alt="New File" className="iconSize" />
        </div>
        <div style={{ marginLeft: '12px' }}>{text}</div>
      </div>
      <RadioSelector
        values={values}
        activeIndexAndValue={activeIndexAndValue}
        setActiveIndexAndValue={setActiveIndexAndValue}
      />
    </div>
  );
};

export default RadioSelectorWrapper;
