import React, { createContext, useContext } from 'react';
import { CircleFill } from 'react-bootstrap-icons';
import style from './BulletSelector.module.scss';

export const ActiveBulletContext = createContext(null);

const CircleButton = (props) => {
  const { index, label, onClick } = props;
  const activeIndex = useContext(ActiveBulletContext);

  const bulletSize = 10;
  const activeStyle = {
    color: '#D4822F',
    transform: 'scale(1.5)',
  };

  return (
    <div className={style.circleWrapper} style={{ fontSize: 0 }}>
      <CircleFill
        style={index === activeIndex ? activeStyle : null}
        className={style.circle}
        size={bulletSize}
        onClick={() => onClick(index)}
      />
      {label && <div className={style.label}>{label}</div>}
    </div>
  );
};

const BulletSelector = (props) => {
  const { values, labels, activeIndexAndValue, setActiveIndexAndValue } = props;

  const { index: activeIndex } = activeIndexAndValue;

  const handleCircleButtonClick = (clickedCircleIndex) => {
    setActiveIndexAndValue({
      index: clickedCircleIndex,
      value: values[clickedCircleIndex],
    });
  };

  return (
    <div
      className={`${style.bulletSelector} mt-3 d-flex flex-nowrap align-items-center`}
    >
      <ActiveBulletContext.Provider value={activeIndex}>
        <CircleButton
          index={0}
          label={labels[0]}
          onClick={handleCircleButtonClick}
        />
        <div className={style.dash} />
        <CircleButton index={1} onClick={handleCircleButtonClick} />
        <div className={style.dash} />
        <CircleButton index={2} onClick={handleCircleButtonClick} />
        <div className={style.dash} />
        <CircleButton index={3} onClick={handleCircleButtonClick} />
        <div className={style.dash} />
        <CircleButton index={4} onClick={handleCircleButtonClick} />
        <div className={style.dash} />
        <CircleButton
          index={5}
          label={labels[5]}
          onClick={handleCircleButtonClick}
        />
      </ActiveBulletContext.Provider>
    </div>
  );
};

export default BulletSelector;
