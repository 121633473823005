import isEqual from 'lodash/isEqual';
import {
  CUSTOM_EDITOR_BLOCK_SYMBOLS_REGEXP,
  NON_SPACE_SYMBOLS_REGEXP,
  SPACE_SYMBOLS_REGEXP,
} from '../constants/constants';

const DEFAULT_EDITOR_CONTENT = {
  blocks: [{ text: '', entityRanges: [], inlineStyleRanges: [] }],
};

const getEditorInlineStyleRanges = (rawEditorContent) =>
  (rawEditorContent.blocks || []).map(
    ({ inlineStyleRanges }) => inlineStyleRanges
  );

const getEditorEntityRanges = (rawEditorContent) =>
  (rawEditorContent.blocks || []).map(({ entityRanges }) => entityRanges);

const getEditorTextWithoutSpacesLength = (rawEditorContent) =>
  (rawEditorContent.blocks || [])
    .map(({ text }) =>
      text
        .replace(CUSTOM_EDITOR_BLOCK_SYMBOLS_REGEXP, '')
        .replace(SPACE_SYMBOLS_REGEXP, '')
    )
    .join('').length;

const getEditorTextSpaceSymbolsLength = (rawEditorContent) =>
  (rawEditorContent.blocks || [])
    .map(({ text }) =>
      text
        .replace(CUSTOM_EDITOR_BLOCK_SYMBOLS_REGEXP, '')
        .replace(NON_SPACE_SYMBOLS_REGEXP, '')
    )
    .join('').length;

const isEditorTextEmpty = (rawEditorContent) =>
  !rawEditorContent ||
  !(rawEditorContent.blocks || []).map(({ text }) => text).join('').length;

export const isEditorTextAdded = (prev, next) =>
  getEditorTextWithoutSpacesLength(prev || DEFAULT_EDITOR_CONTENT) <
  getEditorTextWithoutSpacesLength(next || DEFAULT_EDITOR_CONTENT);

export const isEditorTextRemoved = (prev, next) =>
  (!isEditorTextEmpty(prev || DEFAULT_EDITOR_CONTENT) ||
    !isEditorTextEmpty(next || DEFAULT_EDITOR_CONTENT)) &&
  getEditorTextWithoutSpacesLength(prev || DEFAULT_EDITOR_CONTENT) >
    getEditorTextWithoutSpacesLength(next || DEFAULT_EDITOR_CONTENT);

export const isEditorInlineStylesChanged = (prev, next) =>
  (!isEditorTextEmpty(prev || DEFAULT_EDITOR_CONTENT) ||
    !isEditorTextEmpty(next || DEFAULT_EDITOR_CONTENT)) &&
  !isEqual(
    getEditorInlineStyleRanges(prev || DEFAULT_EDITOR_CONTENT),
    getEditorInlineStyleRanges(next || DEFAULT_EDITOR_CONTENT)
  ) &&
  !isEditorTextAdded(
    prev || DEFAULT_EDITOR_CONTENT,
    next || DEFAULT_EDITOR_CONTENT
  );

export const isEditorCustomBlockCountChanged = (prev, next) =>
  (!isEditorTextEmpty(prev || DEFAULT_EDITOR_CONTENT) ||
    !isEditorTextEmpty(next || DEFAULT_EDITOR_CONTENT)) &&
  !isEqual(
    getEditorEntityRanges(prev || DEFAULT_EDITOR_CONTENT),
    getEditorEntityRanges(next || DEFAULT_EDITOR_CONTENT)
  ) &&
  !isEditorTextAdded(
    prev || DEFAULT_EDITOR_CONTENT,
    next || DEFAULT_EDITOR_CONTENT
  );

export const isEditorSpaceSymbolsAdded = (prev, next) =>
  (!isEditorTextEmpty(prev || DEFAULT_EDITOR_CONTENT) ||
    !isEditorTextEmpty(next || DEFAULT_EDITOR_CONTENT)) &&
  getEditorTextSpaceSymbolsLength(prev || DEFAULT_EDITOR_CONTENT) <
    getEditorTextSpaceSymbolsLength(next || DEFAULT_EDITOR_CONTENT);
