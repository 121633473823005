import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ExclamationTriangle, CheckCircle } from 'react-bootstrap-icons';
import style from './UnsavedProgressModal.module.scss';
// import startRecordingIcon from '../../../assets/Record_StartRecord.svg';
import fileSaveIcon from '../../../assets/File_Save.svg';
import stopRecordingIcon from '../../../assets/Record_StopRecord.svg';
import outputToScreenIcon from '../../../assets/Output_ToScreen.svg';
import ActionListItem from './ActionListItem';
import { t } from '../../../i18n';

// eslint-disable-next-line react/prop-types
const UnsavedProgressModal = ({
  showUnsavedProgressModal,
  handleCloseUnsavedProgressModal,
  unsavedFileAction,
  ongoingRecordingAction,
  activePresentationAction,
  fileUnsaved,
  recordingActive,
  presentationActive,
}) => {
  return (
    <Modal
      show={showUnsavedProgressModal}
      size="sm"
      onHide={handleCloseUnsavedProgressModal}
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={style.unsavedModal}
      centered
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-space-around">
          <div className="d-flex align-items-center justify-content-space-around mt-3">
            <div className="btn-circle btn-md btn-default">
              {fileUnsaved || recordingActive || presentationActive ? (
                <ExclamationTriangle size={24} alt={t('Warning')} />
              ) : (
                <CheckCircle size={24} alt={t('OK')} />
              )}
            </div>
            <h5 style={{ marginLeft: '0.5rem', marginTop: '0.2rem' }}>
              {fileUnsaved || recordingActive || presentationActive
                ? t('Unsaved progress').toUpperCase()
                : t('All saved').toUpperCase()}
            </h5>
          </div>
        </div>
        <div className="text-center my-4">
          {fileUnsaved || recordingActive || presentationActive
            ? t(
                'Please, select which actions to perform before leaving this page.'
              )
            : t('You can safely close this tab now.')}
        </div>
        <div className="d-flex justify-content-center">
          <div className="d-inline-flex flex-column">
            <ActionListItem
              icon={fileSaveIcon}
              label={t('Save editor contents')}
              action={unsavedFileAction}
              visible={fileUnsaved}
            />
            <ActionListItem
              icon={stopRecordingIcon}
              label={t('Stop and save recording')}
              action={ongoingRecordingAction}
              visible={recordingActive}
            />
            <ActionListItem
              icon={outputToScreenIcon}
              label={t('Stop active presentation')}
              action={activePresentationAction}
              visible={presentationActive}
            />
          </div>
        </div>
        <div className={style.modalButtons}>
          <Button
            variant="primary"
            className={`${style.saveButton} btn-square btn-sm btn-secondary mt-4`}
            title={t('Close this popup and get back to editor')}
            onClick={(e) => {
              e.preventDefault();
              handleCloseUnsavedProgressModal();
            }}
          >
            {t('Close').toUpperCase()}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnsavedProgressModal;
