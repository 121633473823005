import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
import { useLongPress } from 'use-long-press';
import s from './ColorsPanel.module.scss';
import ColorPalette from '../ColorPalette';
import { SOURCE_CLICK_COLOR } from '../../constants/constants';
import { HelpTextContext } from '../../contexts/HelpTextContext';
import { t } from '../../i18n';

const ColorsPanel = forwardRef(({ onColorClick }, ref) => {
  // IMPORTANT!
  // Rgb/rgba or hex format only!
  // NO human-readable color names!
  // Color's alpha component is being modified through settings
  const defaultColorsList = [
    {
      color: '#DDCC08',
      title: t('yellow'),
    },
    {
      color: '#8B97B9',
      title: t('blue'),
    },
    {
      color: '#C76953',
      title: t('red'),
    },
    {
      color: '#A7BA4A',
      title: t('green'),
    },
    {
      color: '#8B7E60',
      title: t('brown'),
    },
    {
      color: '#D19C4B',
      title: t('orange'),
    },
    {
      color: '#989898',
      title: t('light grey'),
    },
    {
      color: '#4D4D4D',
      title: t('dark grey'),
    },
  ];

  const [colorsList, setColorsList] = useState(defaultColorsList);
  const [selectedColor, setSelectedColor] = useState(0);
  const [colorTitleEditMode, setEditMode] = useState(null);
  const [colorTitleInputValue, setColorTitle] = useState('');
  const [activePalette, setActivePalette] = useState(null);
  const [tempPaletteColor, setTempColor] = useState(null);
  const paletteRef = useRef(null);

  // Context to hide/show help text
  const helpTextContext = useContext(HelpTextContext);

  const setNewSelectedColor = (index) => {
    setSelectedColor(index);
    if (activePalette !== null && activePalette !== index) {
      setActivePalette(index);
      setTempColor(colorsList[index].color);
    }
    onColorClick(colorsList[index].color, SOURCE_CLICK_COLOR.COLOR_PANEL);
  };

  const setNewSelectedColorFromList = (index, list) => {
    setSelectedColor(index);
    if (activePalette !== null && activePalette !== index) {
      setActivePalette(index);
      setTempColor(list[index].color);
    }
    onColorClick(list[index].color, SOURCE_CLICK_COLOR.COLOR_PANEL);
  };

  /**
   * @param {Object[]} [colorList=defaultColorsList] - array of color objects (default if empty)
   * @param {number} [selectColor] - index of color to select (do not set if empty)
   */
  const setDefaultColorsList = (
    colorList = defaultColorsList,
    selectColor = null
  ) => {
    setColorsList(colorList);
    setActivePalette(null);

    if (selectColor !== null) {
      setNewSelectedColorFromList(selectColor, colorList);
    }
  };

  const handleClickOutside = (e) => {
    const domNode = paletteRef;
    const eventNode = e.target;

    if (domNode.current !== null && !e.target.closest(`.${s.colorCircle}`)) {
      if (!domNode.current.contains(eventNode)) {
        window.removeEventListener('mousedown', handleClickOutside);
        setActivePalette(null);
      }
    }
  };

  const setPaletteIndex = (index) => {
    if (activePalette === index) {
      window.removeEventListener('mousedown', handleClickOutside);
      setActivePalette(null);
    } else {
      setTempColor(colorsList[index].color);
      setActivePalette(index);
      window.addEventListener('mousedown', handleClickOutside);
    }
  };
  const longTap = useLongPress(
    (e) => {
      setPaletteIndex(+e.target.getAttribute('data-color'));
    },
    { captureEvent: true }
  );
  const setNewTitle = (index) => {
    const newColorList = colorsList.map((colorItem, i) => {
      if (i === index) {
        return { ...colorItem, title: colorTitleInputValue };
      }
      return colorItem;
    });

    setColorsList(newColorList);
    setColorTitle('');
    setEditMode(null);
  };

  const setPaletteRef = (refEntity) => {
    paletteRef.current = refEntity;
  };
  const applyNewColor = (title) => {
    const newColorList = [...colorsList];
    newColorList[activePalette] = {
      color: tempPaletteColor.hex ? tempPaletteColor.hex : tempPaletteColor,
      title,
    };
    setColorsList(newColorList);
    // console.log('NEWC!s');
    // setDefaultColorsList(newColorList, selectedColor);
    onColorClick(
      tempPaletteColor.hex ? tempPaletteColor.hex : tempPaletteColor,
      SOURCE_CLICK_COLOR.COLOR_PANEL
    );
    setActivePalette(null);
  };

  useImperativeHandle(ref, () => ({
    setDefaultColorsListRef(colorList, selectColor) {
      setDefaultColorsList(colorList, selectColor);
    },
    getDefaultColorsListRef() {
      return colorsList;
    },
    setSelectedColor(index) {
      setNewSelectedColor(index);
    },
  }));

  return (
    <div
      className={`d-flex flex-column align-items-center ${s.colorsPanelWrapper}`}
    >
      <ul className={`${s.colorsPanel}`} id="colorsPanel">
        {colorsList.map((colorItem, index) => {
          // col-sm-6 col-md-4 col-lg-12 px-0
          return (
            <li
              className={`col-12 px-0 ${s.colorItem} ${
                selectedColor === index && s.activeColor
              }`}
            >
              {activePalette === index && (
                <ColorPalette
                  ref={paletteRef}
                  title={colorItem.title}
                  index={index}
                  color={tempPaletteColor}
                  applySelectColor={applyNewColor}
                  setDefaultSetting={setDefaultColorsList}
                  setPaletteRef={setPaletteRef}
                  onChange={setTempColor}
                />
              )}
              <div
                onMouseDown={() => {
                  // e.preventDefault();
                  setNewSelectedColor(index);
                }}
                className="w-100 d-flex align-items-center"
                style={{ userSelect: 'none' }}
              >
                <div
                  data-color={index}
                  className={s.colorCircle}
                  style={{ backgroundColor: colorItem.color }}
                  {...longTap}
                />
                {colorTitleEditMode === index ? (
                  <input
                    type="text"
                    value={colorTitleInputValue}
                    onChange={(e) => {
                      setColorTitle(e.currentTarget.value);
                    }}
                    className={`d-none d-md-block ${s.titleEditor}`}
                    onBlur={() => {
                      setNewTitle(index);
                    }}
                    autoFocus
                  />
                ) : (
                  <span
                    className={`d-none d-md-block ${s.colorTitle}`}
                    onDoubleClick={() => {
                      setEditMode(index);
                      setColorTitle(colorsList[index].title);
                    }}
                  >
                    {colorItem.title}
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <div
        className={`${s.helpText} ${helpTextContext ? s.dColorsTip : 'd-none'}`}
      >
        <div className={`${s.heading} mb-2`}>{t('Color panel')}</div>
        <div>
          {t(
            'Selectable colors apply to text, underline, text background and commentary fields. Do a long tap or hold your mouse to change and rename colors.'
          )}
        </div>
      </div>
    </div>
  );
});

export default ColorsPanel;
