import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import style from '../ModalsStyle.module.scss';
import styleLocal from './SelectMicrophoneModal.module.scss';
import { t } from '../../../i18n';
import StartRecordIcon from '../../../assets/Record_StartRecord.svg';
import SelectMicrophoneIcon from '../../../assets/Record_SelectMicrophone.svg';

const SelectMicrophoneModal = ({
  onHide,
  show,
  startRecording,
  recSoundActive,
  setRecSoundActive,
}) => {
  const toggleRecSoundActive = () => {
    setRecSoundActive((prev) => !prev);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={style.defaultModal}
      centered
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-space-around">
          <div className="btn-circle btn-sm btn-default">
            <img src={StartRecordIcon} alt="New File" className="iconSize" />
          </div>
          <span className={style.modalText}>
            {t('What would you like to record with or without sound?')}
          </span>
          <div className={style.modalButtons}>
            <div
              className={`${styleLocal.actionWrapper} d-flex mt-2 mb-4 align-items-center`}
              style={{ opacity: recSoundActive ? 1 : 0.5 }}
              onClick={toggleRecSoundActive}
            >
              <div className="btn-circle btn-sm btn-default">
                <img
                  src={SelectMicrophoneIcon}
                  alt={t('microphone')}
                  className="iconSize"
                />
              </div>
              <div className="ml-2">
                {recSoundActive ? t('Mic enabled') : t('Mic disabled')}
              </div>
            </div>
            <Button
              variant="warning"
              className="btn-square btn-sm btn-default"
              onClick={() => {
                startRecording();
                onHide();
              }}
            >
              {t('Record').toUpperCase()}
            </Button>
            <Button
              variant="primary"
              onClick={onHide}
              className="btn-square btn-sm btn-secondary"
            >
              {t('Cancel').toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SelectMicrophoneModal;
