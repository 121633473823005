import React, { useContext, useState } from 'react';
import settingsIcon from '../../assets/Settings.svg';
import helpIcon from '../../assets/Help.svg';
import SettingsModal from '../Modals/SettingsModal';
import ButtonHelpText from '../ButtonHelpText';
import { HelpTextContext } from '../../contexts/HelpTextContext';
import { t } from '../../i18n';

const SettingsButtons = (props) => {
  const { handleResetSettings, handleToggleHelpText } = props;

  const helpTextContext = useContext(HelpTextContext);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const handleHideSettingsModal = () => setShowSettingsModal(false);
  const handleShowSettingsModal = () => setShowSettingsModal(true);

  return (
    <div className="d-flex flex-nowrap justify-content-around">
      <div>
        <ButtonHelpText
          helpText={t('help')}
          variant="secondary"
          className={`btn-circle btn-sm btn-settings ${
            helpTextContext ? 'active' : null
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleToggleHelpText();
          }}
        >
          <img src={helpIcon} alt={t('help')} className="iconSize" />
        </ButtonHelpText>
      </div>
      <div>
        <ButtonHelpText
          helpText={t('settings')}
          variant="secondary"
          className={`btn-circle btn-sm btn-settings ${
            showSettingsModal ? 'active' : null
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleShowSettingsModal();
          }}
        >
          <img src={settingsIcon} alt={t('settings')} className="iconSize" />
        </ButtonHelpText>
        <SettingsModal
          showSettingsModal={showSettingsModal}
          handleCloseSettingsModal={handleHideSettingsModal}
          handleResetSettings={handleResetSettings}
        />
      </div>
    </div>
  );
};
export default SettingsButtons;
