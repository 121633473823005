import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Draggable from 'react-draggable';
import Fragment from 'react-dom-fragment';
import { useLongPress } from 'use-long-press';
import frameMoving from '../../assets/Frame_Moving.svg';
import s from './DrawingItem.module.scss';
import deleteButton from '../../assets/Delete.svg';

const DrawingItem = ({ draw, onChange, onRemove }) => {
  const [active, setActive] = useState(false);
  const wrapper = useRef(null);
  const longTap = useLongPress(() => {
    setActive(true);
  });

  const anyClickDrawings = (e) => {
    const domNode = wrapper;
    const eventNode = e.target;
    if (domNode.current === null) {
      window.removeEventListener('click', anyClickDrawings);
      return;
    }
    if (!domNode.current.contains(eventNode)) {
      setActive(false);
      window.removeEventListener('click', anyClickDrawings);
    }
  };

  const handleDrag = (e, ui) => {
    onChange({ id: draw.id, position: { left: ui.x, top: ui.y } });
  };

  return (
    <Draggable
      onStop={handleDrag}
      defaultPosition={{ x: draw.position.left, y: draw.position.top }}
      position={{ x: draw.position.left, y: draw.position.top }}
      handle={`img.${s.movingIcon}`}
      offsetParent={document.getElementById('drawingItemsSpace')}
    >
      <div
        ref={wrapper}
        className={`drawingItem ${active && 'activeDrawing'}`}
        onClick={() => {
          setActive(true);
          window.addEventListener('click', anyClickDrawings);
        }}
      >
        {active && (
          <img
            draggable="false"
            src={frameMoving}
            alt=""
            className={`iconSize ${s.movingIcon} ${s.drawIcon}`}
            onDragStart={(e) => {
              e.preventDefault();
            }}
          />
        )}
        <Fragment
          dangerouslySetInnerHTML={{ __html: draw.content }}
          {...longTap}
        />
        {active && (
          <Button
            onClick={() => {
              window.removeEventListener('click', anyClickDrawings);
              onRemove(draw.id);
            }}
            variant="warning"
            className={`btn-circle btn-sm btn-default ${s.deleteIcon} ${s.drawIcon}`}
          >
            <img
              src={deleteButton}
              draggable="false"
              alt=""
              className="iconSize"
            />
          </Button>
        )}
      </div>
    </Draggable>
  );
};

export default DrawingItem;
