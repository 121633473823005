import React, { useContext } from 'react';
import style from './withHelpText.module.scss';
import { HelpTextContext } from '../../contexts/HelpTextContext';

function withHelpText(WrappedComponent) {
  return ({ helpText, helpTextWidth = '130%', ...props }) => {
    const helpTextContext = useContext(HelpTextContext);

    const captionExtraStyle = {
      width: helpTextWidth,
      visibility: helpTextContext ? 'visible' : 'hidden',
    };

    const outerDivExtraClass = helpTextContext ? style.applyMinHeight : '';

    return (
      <div className={outerDivExtraClass}>
        <div className={style.buttonWrapper}>
          <WrappedComponent {...props} />
          <div className={style.textCaption} style={captionExtraStyle}>
            {helpText}
          </div>
        </div>
      </div>
    );
  };
}

export default withHelpText;
