export const EXPORT_FILE_NAME = 'my-download';

export const SOURCE_CLICK_COLOR = {
  FORMATTING_BUTTON: 'FORMATTING_BUTTON',
  COLOR_PANEL: 'COLOR_PANEL',
};

export const TEXT_STYLES = {
  BOLD: 'BOLD',
  ITALIC: 'ITALIC',
  UNDERLINE: 'UNDERLINE',
};

export const ACTIVE_EDIT_MODE = {
  TEXT: 'TEXT',
  PAINT: 'PAINT',
  MOVE: 'MOVE',
};

export const CUSTOM_BLOCK_NAME = {
  COMMENT: 'COMMENT',
  HORIZONTAL_LINE: 'HORIZONTAL_LINE',
  INVISIBLE_SPACE: 'INVISIBLE_SPACE',
};

export const TEXT_FIELD_SIZE = {
  WIDTH: 186,
  HEIGHT: 80,
};

export const CUSTOM_EDITOR_BLOCK_SYMBOLS_REGEXP = /­ /g;
export const NON_SPACE_SYMBOLS_REGEXP = /[^\s\t\n]+/g;
export const SPACE_SYMBOLS_REGEXP = /[\s\t\n]+/g;

export const MAX_HISTORY_LENGTH = 100;
