import React from 'react';
import GeneralPage from './pages/GeneralPage';

import './styles/vendor/bootstrap.min.css';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <GeneralPage />
    </div>
  );
};

export default App;
