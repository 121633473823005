import i18n from 'i18next';
import uk from './uk.json';
import de from './de.json';

i18n.init({
  nsSeparator: false, // :
  keySeparator: false, // .
  pluralSeparator: false, // _
  contextSeparator: false, // _

  resources: {
    uk: {
      translation: uk,
    },
    de: {
      translation: de,
    },
  },
  lng: localStorage.getItem('language') || 'uk',
  fallbackLng: 'uk',

  returnEmptyString: false, // take key names as values if values are empty: ""

  interpolation: {
    escapeValue: false,
  },
});

export const t = (...args) => i18n.t(...args);

export default i18n;
