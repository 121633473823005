import React from 'react';
import Undo from '../../assets/Undo.svg';
import Redo from '../../assets/Redo.svg';
import ButtonHelpText from '../ButtonHelpText';
import { preventAll } from '../../utils/prevent';
import { t } from '../../i18n';

const History = ({ undo, redo, canUndo, canRedo }) => {
  return (
    <div className="d-flex flex-nowrap justify-content-around">
      <div>
        <ButtonHelpText
          variant="warning"
          className="btn-circle btn-sm btn-default"
          helpText={t('undo')}
          disabled={!canUndo}
          onClick={(e) => {
            preventAll(e);
            undo();
          }}
        >
          <img src={Undo} alt={t('undo')} className="iconSize" />
        </ButtonHelpText>
      </div>
      <div>
        <ButtonHelpText
          variant="warning"
          className="btn-circle btn-sm btn-default"
          helpText={t('redo')}
          disabled={!canRedo}
          onClick={(e) => {
            preventAll(e);
            redo();
          }}
        >
          <img src={Redo} alt={t('redo')} className="iconSize" />
        </ButtonHelpText>
      </div>
    </div>
  );
};

export default History;
