import isEqual from 'lodash/isEqual';
import {
  SPACE_SYMBOLS_REGEXP,
  NON_SPACE_SYMBOLS_REGEXP,
} from '../constants/constants';

const getCustomBlockTextSpaceSymbolsLength = (customBlocks = []) =>
  customBlocks
    .map(({ content = '' }) => content.replace(NON_SPACE_SYMBOLS_REGEXP, ''))
    .join('').length;

const getCustomBlockTextLength = (customBlocks = []) =>
  customBlocks
    .map(({ content = '' }) => content.replace(SPACE_SYMBOLS_REGEXP, ''))
    .join('').length;

const getCustomBlocksPositions = (customBlocks = []) =>
  customBlocks.map(({ position }) => position);

export const isCustomBlockSpaceSymbolsAdded = (prev, next) =>
  getCustomBlockTextSpaceSymbolsLength(prev) <
  getCustomBlockTextSpaceSymbolsLength(next);

export const isCustomBlockCountChanged = (prev = [], next = []) =>
  prev.length !== next.length;

export const isCustomBlockTextChanged = (prev, next) =>
  getCustomBlockTextLength(prev) < getCustomBlockTextLength(next);

export const isCustomBlockPositionChanged = (prev, next) =>
  !isEqual(getCustomBlocksPositions(prev), getCustomBlocksPositions(next));
