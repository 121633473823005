import React, { useEffect, useState } from 'react';
import { PlayFill } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import StopRecordButton from '../../../assets/Record_StopRecord.svg';
import PauseRecordButton from '../../../assets/Record_Pause.svg';
import s from './RecordPanel.module.scss';
import useStopwatch from './Stopwach';
import { formatTime } from '../../../utils/formatTime';
import { t } from '../../../i18n';

const RecordPanel = ({ stopRecording, pauseRecording, resumeRecording }) => {
  const [pause, setPause] = useState(false);
  const { timer, handlePause, handleResume } = useStopwatch(0);
  useEffect(() => {
    if (!pause) {
      handleResume();
    } else {
      handlePause();
    }
  }, [pause]);

  return (
    <div className={`d-flex align-items-center ${s.recordWrapper} mx-3`}>
      {pause ? (
        <Button
          variant="warning"
          className="btn-circle btn-sm btn-default"
          onClick={() => {
            resumeRecording();
            setPause(false);
          }}
          helpText={t('resume recording')}
        >
          <PlayFill size={20} alt={t('resume recording')} />
        </Button>
      ) : (
        <Button
          variant="warning"
          className="btn-circle btn-sm btn-default"
          onClick={() => {
            pauseRecording();
            setPause(true);
          }}
          helpText={t('pause recording')}
        >
          <img src={PauseRecordButton} alt={t('pause')} className="iconSize" />
        </Button>
      )}
      <div className={s.stopWatch}>{formatTime(timer)}</div>
      <Button
        variant="warning"
        className="btn-circle btn-sm btn-default"
        onClick={stopRecording}
        helpText={t('stop recording')}
      >
        <img src={StopRecordButton} alt={t('stop')} className="iconSize" />
      </Button>
    </div>
  );
};

export default RecordPanel;
