import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Editor,
  EditorState,
  ContentState,
  Modifier,
  CompositeDecorator,
  convertToRaw,
  RichUtils,
  convertFromRaw,
  SelectionState,
} from 'draft-js';
import { isMobile } from 'react-device-detect';
import createStyles from 'draft-js-custom-styles';
import * as clipboard from 'clipboard-polyfill/text';
import { v4 as uuidv4 } from 'uuid';
import {
  CUSTOM_BLOCK_NAME,
  SOURCE_CLICK_COLOR,
  TEXT_STYLES,
  // TEXT_FIELD_SIZE,
} from '../../constants/constants';
import removeCustomEntity, {
  insertCustomEntity,
} from '../../utils/customEditorBlock';
import Comment from '../Comment';
import HorizontalLine from '../HorizontalLine';

const findEntities = (entryType) => (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      !!entityKey && contentState.getEntity(entityKey).getType() === entryType
    );
  }, callback);
};

const colorStyleMap = {};

const { styles, customStyleFn } = createStyles(
  ['background-color', 'color', 'font-size', 'line-height'],
  'custom',
  colorStyleMap
);

const TextEditor = forwardRef(
  (
    {
      editorContainerRef,
      // editorScrollbarRef,
      onStateChange,
      onIsSelectionCollapsedChange,
      setCurrentStyle,
      setCurrentColor,
      setCurrentBackground,
      addTextField,
      onCommentaryClick,
      editorScroll,
      selectedColor,
      editorReadOnly,
    },
    ref
  ) => {
    const [isForceMobileRedraw, setIsForceMobileRedraw] = useState(false);
    const [editorState, setEditorState] = useState(() => {
      const decorator = new CompositeDecorator([
        {
          strategy: findEntities(CUSTOM_BLOCK_NAME.COMMENT),
          component: Comment,
          props: { onClick: onCommentaryClick },
        },
        {
          strategy: findEntities(CUSTOM_BLOCK_NAME.HORIZONTAL_LINE),
          component: HorizontalLine,
        },
      ]);
      return EditorState.createEmpty(decorator);
    });
    const editorComponent = useRef(null);

    useEffect(() => {
      if (!isForceMobileRedraw || !isMobile) {
        return;
      }
      setIsForceMobileRedraw(false);

      editorComponent.current.blur();
      setTimeout(() => {
        editorComponent.current.focus();
      });
    }, [isForceMobileRedraw]);

    const handleEditState = useCallback((state) => {
      const rawState = convertToRaw(state.getCurrentContent());
      onStateChange(rawState);
      onIsSelectionCollapsedChange(state.getSelection().isCollapsed());
      setCurrentColor(styles.color.current(state));
      setCurrentBackground(styles.backgroundColor.current(state));
      setCurrentStyle(state.getCurrentInlineStyle());

      setEditorState(EditorState.set(state, { allowUndo: false }));
    }, []);

    const insertTextField = () => {
      // const { clientWidth, clientHeight } = editorContainerRef.current;
      // const scrollbarWidth = editorScrollbarRef.current.clientWidth;

      const addTextFieldHandler = (event) => {
        console.log('click!');
        const textField = {
          id: uuidv4(),
          color: selectedColor,
          fontSize: '48px',
          /* position: {
            left: (clientWidth - scrollbarWidth - TEXT_FIELD_SIZE.WIDTH) / 2,
            top: (clientHeight - TEXT_FIELD_SIZE.HEIGHT) / 2 + editorScroll,
          }, */
          position: {
            left: event.pageX - editorContainerRef.current.offsetLeft,
            top:
              event.pageY - editorContainerRef.current.offsetTop + editorScroll,
          },
        };
        addTextField(textField);
        editorContainerRef.current.removeEventListener(
          'click',
          addTextFieldHandler
        );
      };

      editorContainerRef.current.addEventListener('click', addTextFieldHandler);
    };

    const insertCommentary = () => {
      const newId = uuidv4();
      const newEditorState = insertCustomEntity(
        editorState,
        CUSTOM_BLOCK_NAME.COMMENT,
        newId
      );
      setIsForceMobileRedraw(true);
      handleEditState(newEditorState);
      return newId;
    };

    const removeCommentary = (id) => {
      const newEditorState = removeCustomEntity(editorState, id);
      setIsForceMobileRedraw(true);
      handleEditState(newEditorState);
    };

    const insertLine = () => {
      const newEditorState = insertCustomEntity(
        editorState,
        CUSTOM_BLOCK_NAME.HORIZONTAL_LINE
      );

      setIsForceMobileRedraw(true);
      handleEditState(newEditorState);
    };

    const insertTab = (tabWidthInSpaces) => {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const currentInlineStyle = editorState.getCurrentInlineStyle();

      const tab = ' '.repeat(tabWidthInSpaces);

      const newContentState = Modifier.insertText(
        contentState,
        selectionState,
        tab,
        currentInlineStyle
      );

      const currentFocusOffset = selectionState.getFocusOffset();
      const currentBlockKey = selectionState.getAnchorKey();
      const newSelectionState = SelectionState.createEmpty(currentBlockKey);
      const selectionStateToSet = newSelectionState.merge({
        anchorOffset: currentFocusOffset + tabWidthInSpaces,
        focusOffset: currentFocusOffset + tabWidthInSpaces,
      });

      const newEditorState = EditorState.forceSelection(
        EditorState.push(editorState, newContentState, 'insert-characters'),
        selectionStateToSet
      );
      setIsForceMobileRedraw(true);
      handleEditState(newEditorState);
    };

    const insertReturn = () => {
      const contentState = Modifier.splitBlock(
        editorState.getCurrentContent(),
        editorState.getSelection()
      );
      const newEditorState = EditorState.push(
        editorState,
        contentState,
        'split-block'
      );
      setIsForceMobileRedraw(true);
      handleEditState(newEditorState);
    };

    const backspaceSimulate = () => {
      const contentState = editorState.getCurrentContent();
      let selectionStateToRemove = editorState.getSelection();
      let selectionStateAfterRemove = selectionStateToRemove;

      if (selectionStateToRemove.isCollapsed()) {
        const currentFocusOffset = selectionStateToRemove.getFocusOffset();
        const currentBlockKey = selectionStateToRemove.getAnchorKey();
        const newSelectionState = SelectionState.createEmpty(currentBlockKey);
        selectionStateToRemove = newSelectionState.merge({
          anchorOffset: currentFocusOffset - 1,
          focusOffset: currentFocusOffset,
        });

        selectionStateAfterRemove = selectionStateToRemove.merge({
          anchorOffset: currentFocusOffset - 1,
          focusOffset: currentFocusOffset - 1,
        });
      } else {
        const currentAnchorOffset = selectionStateToRemove.getAnchorOffset();
        selectionStateAfterRemove = selectionStateToRemove.merge({
          anchorOffset: currentAnchorOffset,
          focusOffset: currentAnchorOffset,
        });
      }

      const newContentState = Modifier.removeRange(
        contentState,
        selectionStateToRemove,
        'backward'
      );

      const newEditorState = EditorState.forceSelection(
        EditorState.push(editorState, newContentState),
        selectionStateAfterRemove
      );

      setIsForceMobileRedraw(true);
      handleEditState(newEditorState);
    };

    // Toggling line-height of selected text, may be useful in future
    const setLineHeight = (lineHeight) => {
      const newEditorState = styles.lineHeight.add(editorState, lineHeight);
      handleEditState(newEditorState);
    };

    // Toggling font-size of selected text, may be useful in future
    const setFontSize = (fontSize) => {
      const newEditorState = styles.fontSize.add(editorState, fontSize);
      handleEditState(newEditorState);
    };

    const getSelection = () => {
      return editorState.getSelection();
    };
    const forceSelection = (selection) => {
      const newEditorState = EditorState.forceSelection(editorState, selection);

      handleEditState(newEditorState);
    };
    const moveFocusToEnd = () => {
      const newEditorState = EditorState.moveFocusToEnd(editorState);

      handleEditState(newEditorState);
    };

    const toggleColor = (toggledColor, sourceClick) => {
      const selection = editorState.getSelection();
      if (
        sourceClick === SOURCE_CLICK_COLOR.COLOR_PANEL &&
        !selection.isCollapsed()
      ) {
        return;
      }
      // console.log(toggledColor, sourceClick);
      const newEditorState = styles.color.toggle(editorState, toggledColor);

      handleEditState(newEditorState);
    };

    const toggleHightLight = (toggledColor) => {
      // const selection = editorState.getSelection();
      // if (selection.isCollapsed()) {
      //   if (styles.backgroundColor.current(editorState)) {
      //     console.log('Remove!');
      //     const newEditorState = styles.backgroundColor.remove(editorState);
      //     handleEditState(newEditorState);
      //   }
      //   return;
      // }
      const newEditorState = styles.backgroundColor.toggle(
        editorState,
        toggledColor
      );

      handleEditState(newEditorState);
    };

    const toggleStyle = (toggledStyle) => {
      const newEditorState = RichUtils.toggleInlineStyle(
        editorState,
        toggledStyle
      );

      setCurrentStyle(newEditorState.getCurrentInlineStyle());

      handleEditState(newEditorState);
    };

    const resetStyles = () => {
      let selection = editorState.getSelection();
      const currentContent = editorState.getCurrentContent();

      if (selection.isCollapsed()) {
        selection = editorState.getSelection().merge({
          anchorKey: currentContent.getFirstBlock().getKey(),
          anchorOffset: 0,

          focusOffset: currentContent.getLastBlock().getText().length,
          focusKey: currentContent.getLastBlock().getKey(),
        });
      }

      const stylesText = Object.keys(TEXT_STYLES).map(
        (style) => TEXT_STYLES[style]
      );

      const nextContentState = stylesText.reduce((contentState, style) => {
        return Modifier.removeInlineStyle(contentState, selection, style);
      }, currentContent);

      let nextEditorState = EditorState.push(editorState, nextContentState);

      nextEditorState = styles.color.remove(nextEditorState);
      nextEditorState = styles.backgroundColor.remove(nextEditorState);

      handleEditState(nextEditorState);
    };

    const importStateFromRaw = (rawState) => {
      const newContentState = convertFromRaw(rawState);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'import-text'
      );
      handleEditState(newEditorState);
    };

    useImperativeHandle(ref, () => ({
      clearContent() {
        const newEditorState = EditorState.push(
          editorState,
          ContentState.createFromText('')
        );
        handleEditState(newEditorState);
      },
      insertClipboard() {
        clipboard.readText().then((result) => {
          const contentState = editorState.getCurrentContent();

          const selectionState = editorState.getSelection();
          let nextContentState;
          if (selectionState.isCollapsed()) {
            nextContentState = Modifier.replaceText(
              contentState,
              selectionState,
              result
            );
          } else {
            nextContentState = Modifier.insertText(
              contentState,
              selectionState,
              result
            );
          }

          const nextEditorState = EditorState.push(
            editorState,
            nextContentState
          );
          handleEditState(nextEditorState);
        });
      },
      toggleColorRef(toggledColor, sourceClick) {
        toggleColor(toggledColor, sourceClick);
      },
      toggleStyleRef(toggledStyle) {
        toggleStyle(toggledStyle);
      },

      // Unused for now
      setFontSizeRef(fontSize) {
        setFontSize(fontSize);
      },
      // Unused for now
      setLineHeightRef(lineHeight) {
        setLineHeight(lineHeight);
      },

      resetStylesRef() {
        resetStyles();
      },
      toggleHightLightRef(toggledColor) {
        toggleHightLight(toggledColor);
      },
      insertLineRef() {
        insertLine();
      },
      insertCommentaryRef() {
        return insertCommentary();
      },
      removeCommentaryRef(id) {
        removeCommentary(id);
      },
      insertTextFieldRef() {
        insertTextField();
      },
      importStateFromFileRef(rawState) {
        importStateFromRaw(rawState);
      },
      insertTabRef(tabWidthInSpaces) {
        insertTab(tabWidthInSpaces);
      },
      insertReturnRef() {
        insertReturn();
      },
      backspaceSimulateRef() {
        backspaceSimulate();
      },
      editorFocusRef() {
        editorComponent.current.focus();
      },
      moveFocusToEndRef() {
        moveFocusToEnd();
      },
      getSelectionRef() {
        return getSelection();
      },
      forceSelectionRef(selection) {
        forceSelection(selection);
      },
      getEditorRef() {
        return editorComponent.current.editor;
      },
    }));

    return (
      <Editor
        ref={editorComponent}
        customStyleFn={customStyleFn}
        customStyleMap={colorStyleMap}
        editorState={editorState}
        onChange={handleEditState}
        readOnly={editorReadOnly}
        onTab={(event) => {
          event.preventDefault();
          insertTab(
            JSON.parse(localStorage.getItem('settings')).tabWidth.value
          );
        }}
      />
    );
  }
);

export default React.memo(TextEditor);
