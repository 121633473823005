import isEqual from 'lodash/isEqual';
import { MAX_HISTORY_LENGTH } from '../constants/constants';

const DEFAULT_HISTORY_STATE = {
  formattedText: null,
  textFields: [],
  comments: [],
  drawings: [],
  painterXML: '',
  isPaintingActive: false,
};

export class HistoryMethods {
  constructor() {
    this.history = [DEFAULT_HISTORY_STATE];
    this.position = 0;
  }

  addIteration(state) {
    if (isEqual(this.history[this.position], state)) {
      return;
    }

    // replace all after position
    if (this.history.length !== this.position + 1) {
      this.history = this.history.slice(0, this.position);
    }

    this.history.push(state);
    this.position += 1;

    // crop history
    if (this.history.length >= MAX_HISTORY_LENGTH) {
      this.history.shift();
      this.position -= 1;
    }
  }

  undo() {
    if (!this.canUndo()) {
      return DEFAULT_HISTORY_STATE;
    }

    this.position -= 1;
    return this.history[this.position];
  }

  redo() {
    if (!this.canRedo()) {
      return DEFAULT_HISTORY_STATE;
    }

    this.position += 1;
    return this.history[this.position];
  }

  canUndo() {
    return this.position > 0;
  }

  canRedo() {
    return this.position + 1 < this.history.length;
  }

  clearHistory() {
    this.history = [DEFAULT_HISTORY_STATE];
    this.position = 0;
  }
}
