import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import style from '../ModalsStyle.module.scss';
import { t } from '../../../i18n';
import newFileIcon from '../../../assets/File_New.svg';

const NewFileConfirm = ({ onHide, show, confirmClearEditor, exportToFile }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={style.defaultModal}
      centered
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-space-around">
          <div className="btn-circle btn-sm btn-default">
            <img src={newFileIcon} width={40} alt={t('new file')} />
          </div>
          <span className={style.modalText}>
            {t('New file.')}
            <br />
            {t('Delete all changes?')}
          </span>
          <div className={style.modalButtons}>
            <Button
              variant="warning"
              onClick={onHide}
              className="btn-square btn-sm btn-default"
            >
              {t('Cancel').toUpperCase()}
            </Button>
            <Button
              variant="primary"
              className="btn-square btn-sm btn-secondary"
              onClick={confirmClearEditor}
            >
              {t('Yes').toUpperCase()}
            </Button>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            onMouseDown={(e) => {
              e.preventDefault();
              exportToFile();
            }}
            variant="link"
            className={style.saveButton}
          >
            {t('Save file')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewFileConfirm;
