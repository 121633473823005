import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import s from '../ModalsStyle.module.scss';
import { t } from '../../../i18n';

const SetDefaultColorsConfirm = ({ onHide, show, confirmAction }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={s.defaultModal}
      centered
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-space-around">
          <span className={s.modalText}>
            {t('Set all colors back to default?')}
          </span>
          <div className={s.modalButtons}>
            <Button
              variant="warning"
              onMouseDown={(e) => {
                e.preventDefault();
                onHide();
              }}
              className="btn-square btn-sm btn-default"
            >
              {t('No').toUpperCase()}
            </Button>
            <Button
              variant="primary"
              className="btn-square btn-sm btn-secondary"
              onMouseDown={confirmAction}
            >
              {t('Yes').toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SetDefaultColorsConfirm;
