import React from 'react';
import BulletSelector from '../BulletSelector';

const BulletSelectorWrapper = (props) => {
  const {
    icon,
    text,
    values,
    labels,
    activeIndexAndValue,
    setActiveIndexAndValue,
  } = props;

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-nowrap align-items-center">
        <div className="btn-circle btn-xs btn-default">
          <img src={icon} alt="New File" className="iconSize" />
        </div>
        <div style={{ marginLeft: '12px' }}>{text}</div>
      </div>
      <BulletSelector
        values={values}
        labels={labels}
        activeIndexAndValue={activeIndexAndValue}
        setActiveIndexAndValue={setActiveIndexAndValue}
      />
    </div>
  );
};

export default BulletSelectorWrapper;
