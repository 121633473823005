import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import { useLongPress } from 'use-long-press';
import { Button } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import s from './CommentField.module.scss';
import deleteButton from '../../assets/Delete.svg';
import { preventAll } from '../../utils/prevent';

const CommentField = ({
  visible,
  content,
  id,
  dependencyText,
  onChange,
  onRemoveClick,
  onHideClick,
  setVisibleCommentId,
}) => {
  const [localVisibility, setLocalVisibility] = useState(false);

  const elem = document.querySelector(`[data-id="${id}"]`);
  const [position, setPosition] = useState({
    top: elem ? elem.offsetTop : 0,
    left: elem ? elem.offsetLeft + 20 : 0,
  });

  useLayoutEffect(() => {
    if (elem) {
      setLocalVisibility(visible);
    }
  }, [visible, elem]);

  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const longTap = useLongPress(() => {
    setIsDeleteMode(true);
  });

  const commentRef = useRef(null);
  const hide = () => {
    if (!visible) {
      return;
    }
    setIsDeleteMode(false);
    onHideClick(id);
  };

  useEffect(() => {
    return () => {
      setVisibleCommentId(null);
    };
  }, []);

  useEffect(() => {
    if (!elem) {
      return;
    }
    if (
      elem.offsetLeft + 20 === position.left &&
      elem.offsetTop === position.top
    ) {
      return;
    }
    setPosition({
      top: elem ? elem.offsetTop : 0,
      left: elem ? elem.offsetLeft + 20 : 0,
    });
  }, [dependencyText, elem]);

  useEffect(() => {
    window.addEventListener('click', hide);

    return () => window.removeEventListener('click', hide);
  }, []);

  return (
    <div
      className={s.commentWrapper}
      ref={commentRef}
      onClick={preventAll}
      style={{
        top: position.top,
        left: position.left,
      }}
      {...longTap}
    >
      {localVisibility && (
        <X size={20} className={s.xButton} onClick={() => onHideClick(id)} />
      )}
      {localVisibility && (
        <>
          <TextareaAutosize
            className={s.commentField}
            name="Comment"
            minRows="3"
            maxRows="18"
            cacheMeasurements
            onChange={(e) => {
              onChange({
                id,
                content: e.target.value,
              });
            }}
            value={content}
            autoFocus
          />
          {isDeleteMode && (
            <Button
              variant="warning"
              type="button"
              className={`btn-circle btn-sm btn-default ${s.deleteIcon}`}
              onClick={() => onRemoveClick(id)}
            >
              <img
                src={deleteButton}
                draggable="false"
                alt=""
                className="iconSize"
              />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default CommentField;
