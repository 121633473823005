import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import ClearFormattingModal from '../Modals/ClearStyleModal';
import {
  ACTIVE_EDIT_MODE,
  SOURCE_CLICK_COLOR,
  TEXT_STYLES,
} from '../../constants/constants';
import modePrint from '../../assets/Mode_Print.svg';
import modeText from '../../assets/Mode_Text.svg';
import textUnderline from '../../assets/Text_UnderlineColor.svg';
import textFillingColor from '../../assets/Text_FillingColor.svg';
import textHighlighter from '../../assets/Text_HighlighterBackground.svg';
import italicStyle from '../../assets/Text_Format_Italic_ENG.svg';
import boldStyle from '../../assets/Text_Format_Bold_ENG.svg';
import clearFormat from '../../assets/Text_Format_Clear.svg';
import insertComment from '../../assets/Insert_CommentaryField.svg';
import insertLine from '../../assets/Insert_PartingLine.svg';
import insertTextField from '../../assets/Insert_TextField.svg';
import insertTextTabButton from '../../assets/Insert_Text_Tab.svg';
import insertTextReturn from '../../assets/Insert_Text_Return.svg';
import insertTextBackspace from '../../assets/Insert_Text_Backspace.svg';
import deleteButton from '../../assets/Delete.svg';
import ClearEditorModal from '../Modals/ClearEditorModal';
import ButtonHelpText from '../ButtonHelpText';
import { t } from '../../i18n';

const TextFormatting = ({
  onStyleButtonsClick,
  onColorButtonClick,
  onHighlightButtonClick,
  onClearButtonClick,
  onLineButtonClick,
  selectedColor,
  currentStyle,
  currentColor,
  onAddCommentaryClick,
  currentBackground,
  onAddTextFieldClick,
  changeEditMode,
  activeMode,
  onDonePainting,
  commentFocus,
  insertTextTab,
  insertReturn,
  onBackspaceButtonClick,
  textFieldsFocus,
  onClearEditorButtonClick,
  isSelectionCollapsed,
}) => {
  const [showClearModal, setShowClearModal] = useState(false);
  const [showClearEditorModal, setShowClearEditorModal] = useState(false);

  const handleCloseClearModal = () => {
    setShowClearModal(false);
  };

  const handleCloseClearEditorModal = () => {
    setShowClearEditorModal(false);
  };

  const clearButtonConfirmHandle = (e) => {
    e.preventDefault();
    onClearButtonClick();
    handleCloseClearModal();
  };

  const clearEditorButtonConfirmHandle = () => {
    onClearEditorButtonClick();
    handleCloseClearEditorModal();
  };

  return (
    <Container className="px-0">
      <div className="d-flex flex-row flex-wrap align-items-center justify-content-around child-mw-150px">
        <div
          className="d-flex justify-content-around my-2"
          style={{ minWidth: 100 }}
        >
          <ButtonHelpText
            helpText={t(`text\nmode`)}
            onMouseDown={(e) => {
              e.preventDefault();
              if (activeMode === ACTIVE_EDIT_MODE.PAINT) {
                onDonePainting();
              }
              changeEditMode(ACTIVE_EDIT_MODE.TEXT);
            }}
            variant="warning"
            className={`btn-circle btn-sm btn-default ${
              activeMode === ACTIVE_EDIT_MODE.TEXT && 'activeStyle'
            }`}
          >
            <img src={modeText} alt={t('text mode')} className="iconSize" />
          </ButtonHelpText>
          <ButtonHelpText
            onMouseDown={(e) => {
              e.preventDefault();
              changeEditMode(ACTIVE_EDIT_MODE.PAINT);
            }}
            variant="warning"
            helpText={t(`drawing\nmode`)}
            className={`btn-circle btn-sm btn-default ${
              activeMode === ACTIVE_EDIT_MODE.PAINT && 'activeStyle'
            }`}
          >
            <img src={modePrint} alt={t('drawing mode')} className="iconSize" />
          </ButtonHelpText>
        </div>
        <div
          className="d-flex justify-content-around my-2"
          style={{ minWidth: 100 }}
        >
          <ButtonHelpText
            onMouseDown={(e) => {
              e.preventDefault();
              onColorButtonClick(
                selectedColor,
                SOURCE_CLICK_COLOR.FORMATTING_BUTTON
              );
            }}
            variant="warning"
            helpText={t(`text\ncolor`)}
            className={`btn-circle btn-sm btn-default ${
              currentColor &&
              currentColor !== 'White' &&
              currentColor !== '#ffffff' &&
              'activeStyle'
            }`}
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={textFillingColor}
              alt={t('text color')}
              className="iconSize"
            />
          </ButtonHelpText>
          <ButtonHelpText
            helpText={t(`highlight\ntext`)}
            onMouseDown={(e) => {
              e.preventDefault();
              onHighlightButtonClick(selectedColor);
            }}
            variant="warning"
            className={`btn-circle btn-sm btn-default ${
              currentBackground && 'activeStyle'
            }`}
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={textHighlighter}
              alt={t('highlight text')}
              className="iconSize"
            />
          </ButtonHelpText>
        </div>
        <div
          className="d-flex justify-content-around my-2"
          style={{ width: 200 }}
        >
          <ButtonHelpText
            helpText={t(`make\ntext bold`)}
            onMouseDown={(e) => {
              e.preventDefault();
              onStyleButtonsClick(TEXT_STYLES.BOLD);
            }}
            variant="warning"
            className={`btn-circle btn-sm btn-default ${
              currentStyle &&
              currentStyle.has(TEXT_STYLES.BOLD) &&
              'activeStyle'
            }`}
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img src={boldStyle} alt={t('bold text')} className="iconSize" />
          </ButtonHelpText>
          <ButtonHelpText
            helpText={t(`make\ntext italic`)}
            onMouseDown={(e) => {
              e.preventDefault();
              onStyleButtonsClick(TEXT_STYLES.ITALIC);
            }}
            variant="warning"
            className={`btn-circle btn-sm btn-default ${
              currentStyle &&
              currentStyle.has(TEXT_STYLES.ITALIC) &&
              'activeStyle'
            }`}
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={italicStyle}
              alt={t('italic text')}
              className="iconSize"
            />
          </ButtonHelpText>
          <ButtonHelpText
            onMouseDown={(e) => {
              e.preventDefault();
              onStyleButtonsClick(TEXT_STYLES.UNDERLINE);
            }}
            variant="warning"
            helpText={t(`under-\nline text`)}
            className={`btn-circle btn-sm btn-default ${
              currentStyle &&
              currentStyle.has(TEXT_STYLES.UNDERLINE) &&
              'activeStyle'
            }`}
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={textUnderline}
              alt={t('underline text')}
              className="iconSize"
            />
          </ButtonHelpText>
          <ButtonHelpText
            helpText={t(`clear text\nformatting`)}
            onMouseDown={(e) => {
              e.preventDefault();

              if (isSelectionCollapsed) {
                setShowClearModal(true);
              } else {
                clearButtonConfirmHandle(e);
              }
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={clearFormat}
              alt={t('clear text formatting')}
              className="iconSize"
            />
          </ButtonHelpText>
          <ClearFormattingModal
            show={showClearModal}
            onHide={handleCloseClearModal}
            confirmAction={clearButtonConfirmHandle}
          />
        </div>
        <div className="d-flex justify-content-around my-2">
          <ButtonHelpText
            helpText={t(`insert\ncomment`)}
            onMouseDown={(e) => {
              e.preventDefault();
              onAddCommentaryClick();
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={insertComment}
              alt={t('insert comment')}
              className="iconSize"
            />
          </ButtonHelpText>
          <ButtonHelpText
            helpText={t(`insert\ntext field`)}
            onClick={() => {
              onAddTextFieldClick();
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              id="insertTextFieldIcon"
              src={insertTextField}
              alt={t('insert text field')}
              className="iconSize"
            />
          </ButtonHelpText>
          <ButtonHelpText
            helpText={t(`insert\nline`)}
            onMouseDown={(e) => {
              e.preventDefault();
              onLineButtonClick();
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img src={insertLine} alt={t('insert line')} className="iconSize" />
          </ButtonHelpText>
        </div>
        <div className="d-flex justify-content-around my-2">
          <ButtonHelpText
            helpText={t('tab')}
            onMouseDown={(e) => {
              e.preventDefault();
              insertTextTab();
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={insertTextTabButton}
              alt={t('insert tab')}
              className="iconSize"
            />
          </ButtonHelpText>
          <ButtonHelpText
            helpText={t('return')}
            onMouseDown={(e) => {
              e.preventDefault();
              insertReturn();
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={insertTextReturn}
              alt={t('return')}
              className="iconSize"
            />
          </ButtonHelpText>
          <ButtonHelpText
            helpText={t('clear')}
            onMouseDown={(e) => {
              e.preventDefault();
              onBackspaceButtonClick();
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
            disabled={
              activeMode === ACTIVE_EDIT_MODE.PAINT ||
              commentFocus ||
              textFieldsFocus
            }
          >
            <img
              src={insertTextBackspace}
              alt={t('clear')}
              className="iconSize"
            />
          </ButtonHelpText>
        </div>
        <div className="d-flex justify-content-around my-2 solo">
          <ButtonHelpText
            helpText={t('delete')}
            onMouseDown={(e) => {
              e.preventDefault();

              if (isSelectionCollapsed) {
                setShowClearEditorModal(true);
              } else {
                onBackspaceButtonClick();
              }
            }}
            variant="warning"
            className="btn-circle btn-sm btn-default"
          >
            <img src={deleteButton} alt={t('delete')} className="iconSize" />
          </ButtonHelpText>
          <ClearEditorModal
            show={showClearEditorModal}
            onHide={handleCloseClearEditorModal}
            confirmAction={clearEditorButtonConfirmHandle}
          />
        </div>
      </div>
    </Container>
  );
};

export default TextFormatting;
