import { useSvgDrawing } from 'react-hooks-svgdrawing';
import { useCallback, useState } from 'react';

export const usePaint = ({ handleChangeDrawing, penWidth }) => {
  const [xml, setXml] = useState('');

  const [
    divRef,
    {
      instance,
      changePenColor,
      changePenWidth,
      getSvgXML,
      download,
      undo,
      clear,
    },
  ] = useSvgDrawing({
    penWidth, // value from settings, initial only!
    penColor: '#000',
  });

  const handleChangeXML = () => {
    setXml(getSvgXML());
    if (!instance) {
      return;
    }
    if (!instance.el.getElementsByTagName('path')[0]) {
      return;
    }
    const svg = instance.el.getElementsByTagName('svg')[0];
    const copy = svg.cloneNode(true);
    const bbox = svg.getBBox({ fill: true, stroke: true, markers: true });

    const padding = 10;

    const padX = bbox.x - padding;
    const padY = bbox.y - padding;
    const padWidth = bbox.width + padding * 2;
    const padHeight = bbox.height + padding * 2;

    const viewBox = [padX, padY, padWidth, padHeight].join(' ');
    copy.setAttribute('viewBox', viewBox);
    copy.setAttribute('width', padWidth);
    copy.setAttribute('height', padHeight);
    handleChangeDrawing(copy.outerHTML, { left: padX, top: padY });
    clear();
  };

  const setSvgXML = (xmlToSet) => {
    console.log('setSvgXML', instance);
    if (!instance) {
      return;
    }

    const currentXML = getSvgXML();
    if (currentXML === xmlToSet) {
      return;
    }

    instance.parseSVGString(xmlToSet);
    instance.update();
  };

  const handleClickDownload = useCallback(
    (ext) => () => {
      download(ext);
    },
    [download]
  );

  return {
    workspace: divRef,
    xml,
    instance,
    changePenColor,
    changePenWidth,
    getSvgXML,
    setSvgXML,
    download,
    undo,
    clear,
    handleChangeXML,
    handleClickDownload,
  };
};
