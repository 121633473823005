import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Container } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import useMediaRecorder from '@wmik/use-media-recorder';
import { isMobile, MobileView } from 'react-device-detect';
import { Decoder, Reader, tools } from 'ts-ebml';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

import logo from '../../assets/CrossloadLogo_Icon.png';
import TextEditor from '../../components/TextEditor';
import FileActions from '../../components/FileActions';
import ColorsPanel from '../../components/ColorsPanel';
import TextFormatting from '../../components/TextFormatting';
import TextField from '../../components/TextField';
import { ACTIVE_EDIT_MODE, EXPORT_FILE_NAME } from '../../constants/constants';
import { usePaint } from '../../utils/usePaint';
import CommentField from '../../components/CommentField';
import DrawingItem from '../../components/DrawingItem';
import History from '../../components/History';
import { HistoryMethods } from '../../utils/history';
import { usePrevious } from '../../utils/usePrevious';
import ErrorExtension from '../../components/Modals/ErrorExtension';
import AndroidKeyboardErrorExtension from '../../components/Modals/AndroidKeyboardErrorExtension';
import Record from '../../components/Record';
import SettingsButtons from '../../components/SettingsButtons';
import { SettingsContext } from '../../contexts/SettingsContext';
import { HelpTextContext } from '../../contexts/HelpTextContext';
import UnsavedProgressModal from '../../components/Modals/UnsavedProgressModal';
import {
  isEditorCustomBlockCountChanged,
  isEditorInlineStylesChanged,
  isEditorTextAdded,
  isEditorTextRemoved,
  isEditorSpaceSymbolsAdded,
} from '../../utils/editorChangesDetection';
import {
  isCustomBlockCountChanged,
  isCustomBlockPositionChanged,
  isCustomBlockSpaceSymbolsAdded,
  isCustomBlockTextChanged,
} from '../../utils/blocksChangesDetection';
import ButtonHelpText from '../../components/ButtonHelpText';
import OpenKeyboardIcon from '../../assets/Open_Keyboard.svg';
import { preventAll } from '../../utils/prevent';
import i18n, { t } from '../../i18n';

const Color = require('color');

const isPresentationSupported = !!window.PresentationRequest;
const isVideoRecordingSupported =
  !!navigator.mediaDevices &&
  !!navigator.mediaDevices.getDisplayMedia &&
  !isMobile;

function cachedFillScreen() {
  let prevTop;

  return function (div, proportional) {
    const currentWidth = div.offsetWidth;
    const currentHeight = div.offsetHeight;

    const availableHeight = window.innerHeight;
    const availableWidth = window.innerWidth;

    let scaleX = availableWidth / currentWidth;
    let scaleY = availableHeight / currentHeight;

    if (proportional) {
      scaleX = Math.min(scaleX, scaleY);
      scaleY = scaleX;
    }

    const translationX = Math.round(
      (availableWidth - currentWidth * scaleX) / 2
    );
    const translationY = Math.round(
      (availableHeight - currentHeight * scaleY) / 2
    );
    prevTop = prevTop || -div.getBoundingClientRect().top;
    return {
      left: 'calc(-6vw - 15px)',
      top: prevTop,
      transform: `translate(${translationX}px, ${translationY}px) scale3d(${scaleX}, ${scaleY}, 1)`,
      transformOrigin: '0 0',
      overflow: 'hidden',
    };
  };
}

const fillScreen = cachedFillScreen();

const GeneralPage = () => {
  const Editor = useRef();
  const ColorsPanelRef = useRef();
  const [isValidExt, setValidExt] = useState(true);
  const [currentStyle, setCurrentStyle] = useState(null);
  const [currentTextColor, setCurrentTextColor] = useState(undefined);
  const [currentBackground, setCurrentBackground] = useState(undefined);
  const [colorPanelSelectedColor, setSelectedColor] = useState('#fff');
  const [editorScroll, setEditorScroll] = useState(0);
  const [editorPlaceHeight, setEditorHeight] = useState('100%');
  const [activeMode, setActiveMode] = useState(ACTIVE_EDIT_MODE.TEXT);
  const activeModeRef = useRef(activeMode);
  const drawingRef = useRef(null);
  const painterRef = useRef(null);
  const editorContainerRef = useRef(null);
  const editorScrollbarRef = useRef(null);
  const history = useRef(new HistoryMethods());
  const [textFieldsFocus, setTextFieldFocus] = useState(false);
  const [isSelectionCollapsed, setIsSelectionCollapsed] = useState(true);
  const [commentFocus, setCommentFocus] = useState(false);
  const previousTextFieldsFocus = usePrevious(textFieldsFocus, false);
  const [isActualFileExported, setIsActualFileExported] = useState(false);
  const [visibleCommentId, setVisibleCommentId] = useState(null);
  const previousVisibleCommentId = usePrevious(visibleCommentId, null);
  const [isEditorJustBlurred, setIsEditorJustBlurred] = useState(false);
  const [isEditorHasUnsavedChanges, setIsEditorHasUnsavedChanges] = useState(
    false
  );

  const [
    isCommentHasUnsavedHistoryChanges,
    setIsCommentHasUnsavedHistoryChanges,
  ] = useState(false);
  const [
    isTextFieldHasUnsavedHistoryChanges,
    setIsTextFieldHasUnsavedHistoryChanges,
  ] = useState(false);
  const [isHistoryChange, setIsHistoryChange] = useState(false);
  const [
    isUnsupportedAndroidKeyboardErrorVisible,
    setIsUnsupportedAndroidKeyboardErrorVisible,
  ] = useState(false);

  const [rawEditorState, setRawEditorState] = useState(null);
  const [textFields, setTextFields] = useState([]);
  const [comments, setComments] = useState([]);
  const [drawings, setDrawings] = useState([]);
  const previousRawEditorState = usePrevious(rawEditorState, null);
  const previousTextFields = usePrevious(textFields, []);
  const previousComments = usePrevious(comments, []);
  const previousDrawings = usePrevious(drawings, []);

  const [savedSettings, setSavedSettings] = useState(
    JSON.parse(localStorage.getItem('settings'))
  );

  //
  //
  // Video recording block
  //

  const [pendingMediaBlobUrl, setPendingMediaBlobUrl] = useState(null);
  const [recSoundActive, setRecSoundActive] = useState(false);

  const resetPendingMediaBlobUrl = () => {
    setPendingMediaBlobUrl(null);
  };

  /**
   * @param {Blob} mediaBlob
   */
  const onRecordingStop = (mediaBlob) => {
    const decoder = new Decoder();
    const reader = new Reader();

    console.log('Stop!');
    console.log(mediaBlob);

    if (mediaBlob.type !== 'video/mp4') {
      mediaBlob.arrayBuffer().then((buffer) => {
        const ebmlElms = decoder.decode(buffer);
        ebmlElms.forEach((elm) => {
          reader.read(elm);
        });
        reader.stop();
        const refinedMetadataBuf = tools.makeMetadataSeekable(
          reader.metadatas,
          reader.duration,
          reader.cues
        );
        const body = buffer.slice(reader.metadataSize);
        const refinedWebM = new Blob([refinedMetadataBuf, body], {
          type: mediaBlob.type,
        });

        const blobUrl = URL.createObjectURL(refinedWebM);
        setPendingMediaBlobUrl(blobUrl);
      });
    } else {
      const blobUrl = URL.createObjectURL(mediaBlob);
      setPendingMediaBlobUrl(blobUrl);
    }
  };

  const onRecordingError = (error) => {
    console.log(error);
  };

  // Prevent mobile keyobard from showing on focus
  useEffect(() => {
    const editableDiv = document.querySelector('.public-DraftEditor-content');
    editableDiv.setAttribute('inputmode', 'none');
    editableDiv.addEventListener('blur', () => {
      editableDiv.setAttribute('inputmode', 'none');
    });
  }, []);

  const getMimesAndCodecsList = useCallback(
    (withAudio) => {
      // if (isSafari) {
      //   return [
      //     {
      //       mimeType: 'video/mp4',
      //       codec: 'video/mp4;codecs=avc1',
      //     },
      //   ];
      // }

      const mimeTypes = {
        mp4: {
          video: ['h264', 'h.264', 'avc1', 'vp8', 'vp9', 'h265', 'h.265'],
          audio: ['pcm', 'opus', 'vorbis', 'aac'],
        },
        webm: {
          video: ['vp8', 'vp9', 'h264', 'h.264', 'avc1', 'h265', 'h.265'],
          audio: ['opus', 'pcm', 'vorbis'],
        },
        'x-matroska': {
          video: ['vp8', 'vp9', 'h264', 'h.264', 'avc1', 'h265', 'h.265'],
          audio: ['opus', 'pcm', 'vorbis'],
        },
      };

      const mimesAndCodecsToUse = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const [container, codecs] of Object.entries(mimeTypes)) {
        const mimeString = `video/${container}`;

        if (MediaRecorder.isTypeSupported(mimeString)) {
          // eslint-disable-next-line no-restricted-syntax
          for (const videoCodec of codecs.video) {
            const videoTestString = `${mimeString};codecs=${videoCodec}`;

            // Check is necessary!
            if (!withAudio) {
              if (MediaRecorder.isTypeSupported(videoTestString)) {
                mimesAndCodecsToUse.push({
                  mimeType: mimeString,
                  codec: videoTestString,
                });
              }
            } else {
              // eslint-disable-next-line no-restricted-syntax
              for (const audioCodec of codecs.audio) {
                const audioTestString = `${videoTestString},${audioCodec}`;
                if (MediaRecorder.isTypeSupported(audioTestString)) {
                  mimesAndCodecsToUse.push({
                    mimeType: mimeString,
                    codec: audioTestString,
                  });
                }
              }
            }
          }
        }
        // else {
        //   console.log(`${mimeString} is not supported!`);
        // }
      }
      return mimesAndCodecsToUse;
    },
    [recSoundActive]
  );

  const [bestCodec, setBestCodec] = useState('video/webm;codecs=vp8,opus');
  const [bestMimeType, setBestMimeType] = useState('video/webm');

  useEffect(() => {
    if (!getMimesAndCodecsList(recSoundActive).length) {
      const { codec, mimeType } = getMimesAndCodecsList(false)[0];
      setBestCodec(codec);
      setBestMimeType(mimeType);
    } else {
      const { codec, mimeType } = getMimesAndCodecsList(recSoundActive)[0];
      setBestCodec(codec);
      setBestMimeType(mimeType);
    }
  }, [recSoundActive]);

  let recordingStatus;
  let startRecording;
  let pauseRecording;
  let resumeRecording;
  let stopRecording;
  let clearMediaStream;
  let recError;

  if (isVideoRecordingSupported) {
    ({
      status: recordingStatus,
      startRecording,
      pauseRecording,
      resumeRecording,
      stopRecording,
      clearMediaStream,
      error: recError,
    } = useMediaRecorder({
      recordScreen: true,
      blobOptions: { type: bestMimeType },
      mediaRecorderOptions: {
        audioBitsPerSecond: 128 * 1000,
        videoBitsPerSecond: 6 * 1000 * 1000,
        mimeType: bestCodec,
      },
      mediaStreamConstraints: { audio: recSoundActive, video: true },
      onStop: onRecordingStop,
      onError: (e) => alert(e) || onRecordingError(e),
    }));

    useEffect(() => {
      clearMediaStream();
    }, [recError]);
  }

  // Generic saveData function
  const saveData = (data, fileName) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'octet/stream' });
    saveAs(blob, fileName);
  };

  //
  // End video recording block
  //

  // New state to work with help text
  //
  // Help text visibility
  const [helpTextVisible, setHelpTextVisible] = useState(false);

  const toggleHelpText = () => {
    setHelpTextVisible((prevState) => {
      return !prevState;
    });
  };

  // New states to work with settings
  //
  // Pen width for drawing
  const penWidthValues = [1, 2, 3, 4, 5, 6];

  const initialPenWidthIndex = 2;
  const initPenWidthState = (
    index = savedSettings ? savedSettings.penWidth.index : initialPenWidthIndex
  ) => {
    return {
      index,
      value: penWidthValues[index],
    };
  };
  const [penWidth, setPenWidth] = useState(() => initPenWidthState());

  // Tab width in spaces
  const tabWidthValues = [2, 4, 6, 8, 10, 12];
  const initialTabWidthIndex = 1;
  const initTabWidthState = (
    index = savedSettings ? savedSettings.tabWidth.index : initialTabWidthIndex
  ) => {
    return {
      index,
      value: tabWidthValues[index],
    };
  };
  const [tabWidth, setTabWidth] = useState(() => initTabWidthState());

  // Pen transparency values (alpha component float 0 - 1)
  const penTransparencyValues = [0.25, 0.4, 0.55, 0.7, 0.85, 1]; // 0.15 step
  const penTransparencyLabels = penTransparencyValues.map(
    (value) => `${String((1 - value) * 100)}%`
  );
  const initialPenTransparencyIndex = 5;
  const initPenTransparencyState = (
    index = savedSettings
      ? savedSettings.penTransparency.index
      : initialPenTransparencyIndex
  ) => {
    return {
      index,
      value: penTransparencyValues[index],
    };
  };
  const [penTransparency, setPenTransparency] = useState(() =>
    initPenTransparencyState()
  );

  // Font size values
  const fontSizeValues = ['12px', '16pxt', '20px', '24px', '28px', '32px'];
  const fontSizeLabels = ['12px', '16px', '20px', '24px', '28px', '32px'];
  const initialFontSizeIndex = 3;
  const initFontSizeState = (
    index = savedSettings ? savedSettings.fontSize.index : initialFontSizeIndex
  ) => {
    return {
      index,
      value: fontSizeValues[index],
    };
  };
  const [fontSize, setFontSize] = useState(() => initFontSizeState());

  // Line height settings
  const lineHeightValues = ['1', '1.2', '1.5', '1.85', '2', '2.5'];
  const initialLineHeightIndex = 2;
  const initLineHeightState = (
    index = savedSettings
      ? savedSettings.lineHeight.index
      : initialLineHeightIndex
  ) => {
    return {
      index,
      value: lineHeightValues[index],
    };
  };
  const [lineHeight, setLineHeight] = useState(() => initLineHeightState());

  // Language settings
  const languageValues = ['de', 'en'];
  const initialLanguageIndex = 1;
  const initLanguageState = (
    index = savedSettings ? savedSettings.language.index : initialLanguageIndex
  ) => {
    i18n.changeLanguage(languageValues[index]);

    return {
      index,
      value: languageValues[index],
    };
  };
  const [language, setLanguage] = useState(() => initLanguageState());

  // Reset values
  const handleResetSettings = useCallback(() => {
    localStorage.removeItem('settings');
    setSavedSettings(null);
    setPenWidth(initPenWidthState(initialPenWidthIndex));
    setTabWidth(initTabWidthState(initialTabWidthIndex));
    setPenTransparency(initPenTransparencyState(initialPenTransparencyIndex));
    setFontSize(initFontSizeState(initialFontSizeIndex));
    setLineHeight(initLineHeightState(initialLineHeightIndex));
    ColorsPanelRef.current.setDefaultColorsListRef(undefined, 0);
    setLanguage(initLanguageState(initialLanguageIndex));
  }, []);

  // Set values from settings object
  const setSettingsFromSettingsObj = (settingsObj) => {
    // Renaming object properties to not interfere with outer scope
    const {
      fontSize: _fontSize,
      language: _language,
      lineHeight: _lineHeight,
      penTransparency: _penTransparency,
      penWidth: _penWidth,
      tabWidth: _tabWidth,
      colorPanelDefaultColorsList: _colorPanelDefaultColorsList,
    } = settingsObj;
    setPenWidth(initPenWidthState(_penWidth.index));
    setTabWidth(initTabWidthState(_tabWidth.index));
    setPenTransparency(initPenTransparencyState(_penTransparency.index));
    setFontSize(initFontSizeState(_fontSize.index));
    setLineHeight(initLineHeightState(_lineHeight.index));
    setLanguage(initLanguageState(_language.index));
    // Control ColorPanel via refs
    ColorsPanelRef.current.setDefaultColorsListRef(
      _colorPanelDefaultColorsList,
      0
    );
  };

  // Generate settings object
  const getSettingsObject = () => {
    return {
      penWidth,
      tabWidth,
      penTransparency,
      fontSize,
      lineHeight,
      language,
      colorPanelDefaultColorsList: ColorsPanelRef.current.getDefaultColorsListRef(),
    };
  };

  // Exporting settings + active selected color
  const exportSettings = () => {
    const settingsObject = getSettingsObject();

    const fileName = 'my_settings.clxps';

    saveData(settingsObject, fileName);
  };

  // Importing settings from file
  const importSettings = (file) => {
    const reader = new FileReader();

    let ext = '';
    const parts = file.name.split('.');
    if (parts.length > 1) ext = parts.pop();

    if (ext === 'clxps') {
      reader.readAsText(file);

      reader.onload = function () {
        const result = JSON.parse(reader.result);
        setSettingsFromSettingsObj(result);
      };

      reader.onerror = function () {
        console.log(reader.error);
      };
    }
  };
  //
  // End of Settings block
  //

  //
  // 2nd Screen Presentation block
  //
  const presentationConnectionRef = useRef(null);
  const presentationRequestRef = useRef(null);
  const editorBlockRef = useRef(null);
  const [isPresentationActive, setIsPresentationActive] = useState(false);

  const [presentationMode, setPresentationMode] = useState(false);
  const [presentationErrorText, setPresentationErrorText] = useState(null);
  const [presentationAvailability, setPresentationAvailability] = useState(
    true
  );

  useEffect(() => {
    const elem = document.querySelector('.editorBlock');
    elem.style.width = '1000px !important';
  }, []);

  const clearPresentationErrorText = () => {
    setPresentationErrorText(null);
  };

  const getStateToExport = () => {
    console.log({
      rawEditorState,
      comments,
      drawings,
      textFields,
      settingsObj: getSettingsObject(),
    });
    return {
      rawEditorState,
      comments,
      drawings,
      textFields,
      settingsObj: getSettingsObject(),
    };
  };

  const importStateFromRawState = (rawState) => {
    Editor.current.importStateFromFileRef(rawState);
  };

  const applyImportedState = (stateObj) => {
    if (stateObj.presentationMode) {
      setPresentationMode(stateObj.presentationMode);
    }
    if (stateObj.rawEditorState) {
      importStateFromRawState(stateObj.rawEditorState);
    }
    if (stateObj.comments) {
      setComments(stateObj.comments);
    }
    if (stateObj.drawings) {
      setDrawings(stateObj.drawings);
    }
    if (stateObj.textFields) {
      setTextFields(stateObj.textFields);
    }
    if (stateObj.settingsObj) {
      setSettingsFromSettingsObj(stateObj.settingsObj);
    }
  };

  const initPresentation = useCallback(() => {
    try {
      presentationRequestRef.current = new PresentationRequest(['/']);

      presentationRequestRef.current
        .getAvailability()
        .then((_availability) => {
          setPresentationAvailability(_availability.value);
          // console.log(_availability.value);
          // console.log(`Available presentation displays: ${_availability.value}`);

          _availability.addEventListener('change', function () {
            setPresentationAvailability(_availability.value);
            // console.log(
            //   `> Available presentation displays: ${_availability.value}`
            // );
          });
        })
        .catch((error) => {
          console.log(
            `Presentation availability not supported, ${error.name}: ${error.message}`
          );
        });

      // Make this presentation the default one when using the "Cast" browser menu.
      navigator.presentation.defaultRequest = presentationRequestRef.current;

      presentationRequestRef.current
        .start()
        .then((connection) => {
          console.log(`> Connected to ${connection.url}, id: ${connection.id}`);
        })
        .catch((err) => {
          console.log(`> ${err.name}: ${err.message}`);
        });

      presentationRequestRef.current.addEventListener(
        'connectionavailable',
        (event) => {
          setIsPresentationActive(true);
          presentationConnectionRef.current = event.connection;
          presentationConnectionRef.current.addEventListener('close', () => {
            setIsPresentationActive(false);
            console.log('> Connection closed.');
          });
          presentationConnectionRef.current.addEventListener(
            'terminate',
            () => {
              setIsPresentationActive(false);
              console.log('> Connection terminated.');
            }
          );
          // presentationConnectionRef.current.addEventListener(
          //   'message',
          //   (e) => {
          //     console.log(`> ${e.data}`);
          //   }
          // );
        }
      );
    } catch (err) {
      console.log(err);
      setPresentationErrorText(
        t(
          'Unfortunately, presentation to the second screen is not supported by your current browser. Please, use the latest version of Google Chrome for this feature to become available.'
        )
      );
    }
  }, []);

  const handleTerminate = () => {
    if (presentationConnectionRef.current) {
      presentationConnectionRef.current.terminate();
    }
  };

  const handleSendMessage = (message) => {
    try {
      // console.log(`Sending "${message}"...`);
      // console.log(message);
      presentationConnectionRef.current.send(JSON.stringify(message));
    } catch (e) {
      // console.log(e);
      console.log('Message not sent!');
    }
  };

  useEffect(() => {
    if (presentationConnectionRef.current) {
      handleSendMessage({
        ...getStateToExport(),
        activeMode,
        presentationMode: true,
      });
    }
    // return () => {
    //   if (presentationConnectionRef.current) {
    //     presentationConnectionRef.current.terminate();
    //   }
    // };
  }, [
    rawEditorState,
    comments,
    drawings,
    textFields,
    fontSize,
    lineHeight,
    activeMode,
  ]);

  //
  // Receiver
  //
  const connectionIdx = useRef(0);
  const messageIdx = useRef(0);
  // const prevMessage = useRef(null);

  const applyStateFromMessage = useCallback((message) => {
    applyImportedState(message);
  }, []);

  const scrollEditorOnSecondScreen = (scrollTopPercentage) => {
    const element = document.getElementsByClassName('DraftEditor-root')[0];
    const parent = document.querySelector('[data-contents="true"]');

    const scrollPostion =
      (parent.offsetHeight - element.offsetHeight) *
      (scrollTopPercentage / 100);

    element.scrollTop = Math.round(scrollPostion);
  };

  const addConnection = useCallback((connection) => {
    connectionIdx.current += 1;
    connection.connectionId = connectionIdx.current;

    connection.addEventListener('message', function (event) {
      messageIdx.current += 1;
      const data = JSON.parse(event.data);
      const logString = `Message ${messageIdx.current} from connection #${connection.connectionId}: ${data}`;
      console.log(logString);
      applyStateFromMessage(data);
      setActiveMode(data.activeMode);
      if (data.event === 'editorScroll') {
        scrollEditorOnSecondScreen(data.value);
      }
      connection.send(`Received message ${messageIdx.current}`);
    });

    connection.addEventListener('close', function (event) {
      console.log(
        `Connection #${connection.connectionId} closed, reason = ${event.reason}, message = ${event.message}`
      );
    });
  }, []);

  const [
    presentationEditorContainerStyles,
    setPresentationEditorContainerStyles,
  ] = useState({});
  useLayoutEffect(() => {
    document.addEventListener('DOMContentLoaded', function () {
      try {
        if (navigator.presentation.receiver) {
          document.body.style.overflow = 'hidden';
          setTimeout(() => {
            setPresentationMode(true);
          }, 50);

          const editorBlock = document.querySelector('.editorBlock');
          setPresentationEditorContainerStyles({
            minWidth: `${(editorBlock.offsetHeight / 9) * 16}px`,
            flexGrow: 0,
          });
          navigator.presentation.receiver.connectionList.then((list) => {
            list.connections.map((connection) => addConnection(connection));
            list.addEventListener('connectionavailable', function (event) {
              addConnection(event.connection);
            });
          });
        }
      } catch (e) {
        console.log(e);
      }
    });
  }, []);

  //
  // End of 2nd Screen Presentation block
  //

  const addHistoryIteration = () => {
    const newState = {
      rawEditorState,
      comments,
      textFields,
      drawings,
      isPaintingActive: activeMode === ACTIVE_EDIT_MODE.PAINT,
      visibleCommentId,
    };

    console.log('addHistoryIteration', newState);

    setIsCommentHasUnsavedHistoryChanges(false);
    setIsEditorHasUnsavedChanges(false);
    setIsTextFieldHasUnsavedHistoryChanges(false);
    history.current.addIteration(newState);
  };

  const clearEditor = () => {
    Editor.current.clearContent();
  };

  useEffect(() => {
    if (visibleCommentId) {
      setCommentFocus(true);
    } else {
      setCommentFocus(false);
    }
  }, [visibleCommentId]);

  // const commentFocus = useMemo(() => {
  //   return comments.some((com) => com.visible);
  // }, [comments]);

  const addNewComment = useCallback(() => {
    const id = Editor.current.insertCommentaryRef();

    const newCommentariesList = [...comments];
    newCommentariesList.push({
      id,
      visible: false,
      content: '',
    });
    setComments(newCommentariesList);

    setTimeout(() => setVisibleCommentId(id), 500);
  }, [comments]);
  const showCommentary = useCallback((id) => {
    setVisibleCommentId(id);
  }, []);
  const hideCommentary = useCallback(() => {
    setVisibleCommentId(null);
  }, []);

  const changeCommentary = useCallback(
    (newComment) => {
      const newCommentariesList = comments.map((comment) => {
        if (comment.id === newComment.id) {
          return {
            ...comment,
            ...newComment,
          };
        }
        return comment;
      });

      setComments(newCommentariesList);
    },
    [comments]
  );

  const removeComment = useCallback(
    (id) => {
      Editor.current.removeCommentaryRef(id);

      const newCommentariesList = comments.filter(
        (comment) => comment.id !== id
      );

      setComments(newCommentariesList);
    },
    [comments]
  );

  const allowScrollEditor = useRef(true);
  const allowScrollDraw = useRef(true);

  const scrollEditorEvent = (e) => {
    if (navigator.presentation && navigator.presentation.defaultRequest) {
      const parent = document.querySelector('[data-contents="true"]');
      // handleSendMessage({ event: 'editorScroll', value: e.target.scrollTop });
      handleSendMessage({
        event: 'editorScroll',
        value: Math.round(
          (e.target.scrollTop / (parent.offsetHeight - e.target.offsetHeight)) *
            100
        ),
      });
    }

    /* console.log(
      Math.round(
        (e.target.scrollTop / (parent.offsetHeight - e.target.offsetHeight)) *
          100
      )
    ); */

    const editorView = document.getElementsByClassName('DraftEditor-root')[0];
    if (
      e.currentTarget.className === 'DraftEditor-root' &&
      activeModeRef.current === ACTIVE_EDIT_MODE.TEXT
    ) {
      drawingRef.current.scrollTop = editorView.scrollTop;
      painterRef.current.scrollTop = editorView.scrollTop;
    } else if (
      e.currentTarget.className === 'DraftEditor-root' &&
      (activeModeRef.current === ACTIVE_EDIT_MODE.PAINT ||
        activeModeRef.current === ACTIVE_EDIT_MODE.MOVE)
    ) {
      if (allowScrollEditor.current) {
        drawingRef.current.scrollTop = editorView.scrollTop;
        painterRef.current.scrollTop = editorView.scrollTop;
        allowScrollDraw.current = false;
        // console.log('Scroll editor!');
      } else {
        allowScrollEditor.current = true;
      }
    } else if (
      e.currentTarget.className === 'drawingWrapper' &&
      activeModeRef.current === ACTIVE_EDIT_MODE.PAINT
    ) {
      if (allowScrollDraw.current) {
        editorView.scrollTop = e.target.scrollTop;
        drawingRef.current.scrollTop = e.target.scrollTop;
        allowScrollEditor.current = false;
        // console.log('Scroll draw!');
      } else {
        allowScrollDraw.current = true;
      }
    } else if (
      e.currentTarget.className === 'drawingWrapper' &&
      activeModeRef.current === ACTIVE_EDIT_MODE.MOVE
    ) {
      if (allowScrollDraw.current) {
        editorView.scrollTop = e.target.scrollTop;
        painterRef.current.scrollTop = e.target.scrollTop;
        allowScrollEditor.current = false;
        // console.log('Scroll draw!');
      } else {
        allowScrollDraw.current = true;
      }
    }
    setEditorScroll(editorView.scrollTop);
  };

  const addDrawingFigure = (el, position) => {
    const drawObject = {
      id: uuidv4(),
      content: el,
      position,
    };
    const newDrawings = [...drawings];
    newDrawings.push(drawObject);
    setDrawings(newDrawings);
  };

  const painter = usePaint({
    handleChangeDrawing: addDrawingFigure,
    penWidth: penWidth.value,
  });

  const applyHistory = (actualState) => {
    if (actualState.rawEditorState) {
      importStateFromRawState(actualState.rawEditorState);
    } else {
      clearEditor();
    }
    setIsActualFileExported(false);
    setIsHistoryChange(true);
    setVisibleCommentId(actualState.visibleCommentId);
    setDrawings(actualState.drawings);
    setComments(actualState.comments);
    setTextFields(actualState.textFields);
    setActiveMode(
      actualState.isPaintingActive
        ? ACTIVE_EDIT_MODE.PAINT
        : ACTIVE_EDIT_MODE.TEXT
    );
  };

  const undoAction = useCallback(() => {
    const actualState = history.current.undo();
    applyHistory(actualState);
  }, [history.current, painter]);

  const redoAction = useCallback(() => {
    const actualState = history.current.redo();
    applyHistory(actualState);
  }, [history.current, painter]);

  // Extra code to update pen width on Settings changes after painter init
  useEffect(() => {
    painter.changePenWidth(penWidth.value);
  }, [penWidth]);

  const clearWorkSpace = () => {
    clearEditor();
    painter.clear();
    setTextFields([]);
    setComments([]);
    setDrawings([]);
    setActiveMode(ACTIVE_EDIT_MODE.TEXT);
    history.current.clearHistory();
  };
  const resetEditor = () => {
    clearEditor();
    setTextFields([]);
    setComments([]);
    setDrawings([]);
  };

  const changeEditMode = (mode) => {
    if (activeMode === mode) {
      return;
    }
    const editorView = document.getElementsByClassName('DraftEditor-root')[0];
    if (mode === ACTIVE_EDIT_MODE.PAINT) {
      drawingRef.current.removeEventListener('scroll', scrollEditorEvent);
      editorView.removeEventListener('scroll', scrollEditorEvent);
      painterRef.current.addEventListener('scroll', scrollEditorEvent);
    } else if (mode === ACTIVE_EDIT_MODE.TEXT) {
      drawingRef.current.removeEventListener('scroll', scrollEditorEvent);
      painterRef.current.removeEventListener('scroll', scrollEditorEvent);
      editorView.addEventListener('scroll', scrollEditorEvent);
    } else if (mode === ACTIVE_EDIT_MODE.MOVE) {
      painterRef.current.removeEventListener('scroll', scrollEditorEvent);
      editorView.removeEventListener('scroll', scrollEditorEvent);
      drawingRef.current.addEventListener('scroll', scrollEditorEvent);
    }
    setActiveMode(mode);
    activeModeRef.current = mode;
  };

  const addNewTextField = useCallback(
    (textField) => {
      const newTextFields = [...textFields];
      newTextFields.push(textField);
      setTextFields(newTextFields);
    },
    [textFields]
  );
  const changeTextField = useCallback(
    (textField) => {
      const newTextFields = [...textFields];
      const index = newTextFields.findIndex(
        (value) => value.id === textField.id
      );
      newTextFields[index] = { ...newTextFields[index], ...textField };
      setTextFields(newTextFields);
    },
    [textFields]
  );
  const removeTextField = useCallback(
    (id) => {
      let newTextFields = [...textFields];
      newTextFields = newTextFields.filter((field) => field.id !== id);
      setTextFields(newTextFields);
    },
    [textFields]
  );
  const changeDrawingItem = useCallback(
    (drawing) => {
      const newDrawings = [...drawings];
      const index = newDrawings.findIndex((value) => value.id === drawing.id);
      newDrawings[index] = { ...newDrawings[index], ...drawing };
      setDrawings(newDrawings);
    },
    [drawings]
  );
  const removeDrawingItem = useCallback(
    (id) => {
      let newDrawings = [...drawings];
      newDrawings = newDrawings.filter((field) => field.id !== id);
      setDrawings(newDrawings);
    },
    [drawings]
  );

  const insertClipboardText = useCallback(() => {
    Editor.current.insertClipboard();
  }, []);

  // const getEditorSelection = () => {
  //   return Editor.current.getSelectionRef();
  // };
  // const forceEditorSelection = (selection) => {
  //   return Editor.current.forceSelectionRef(selection);
  // };
  const toggleTextColor = useCallback((toggledColor, sourceClick) => {
    Editor.current.toggleColorRef(toggledColor, sourceClick);
  }, []);

  // function to add alpha component from settings to color
  const addAlphaToColor = (color) => {
    // Pen color's alpha component is being modified through settings
    const colorWithAlphaObject = {
      ...Color(color).object(),
      alpha: penTransparency.value, // penTransparency from state
    };
    return Color(colorWithAlphaObject).rgb();
  };

  const toggleColor = (toggledColor, sourceClick) => {
    setSelectedColor(toggledColor);
    // Toggling pen color only
    painter.changePenColor(addAlphaToColor(toggledColor));
    // Retaining text color
    toggleTextColor(toggledColor, sourceClick);
  };

  const setRawEditorStateAndTrackChanges = useCallback(
    (newRawEditorState) => {
      setRawEditorState(newRawEditorState);

      if (isEditorTextAdded(rawEditorState, newRawEditorState)) {
        setIsEditorHasUnsavedChanges(true);
      }
    },
    [rawEditorState]
  );

  // Update pen transparency on settings change
  useEffect(() => {
    painter.changePenColor(addAlphaToColor(colorPanelSelectedColor));
  }, [penTransparency]);

  const toggleTextHighlight = (toggledColor) => {
    Editor.current.toggleHightLightRef(toggledColor);
  };
  const toggleTextStyle = (toggledStyle) => {
    Editor.current.toggleStyleRef(toggledStyle);
  };
  const resetStyles = () => {
    Editor.current.resetStylesRef();
  };
  const insertLine = () => {
    Editor.current.insertLineRef();
  };
  const insertTextField = () => {
    Editor.current.insertTextFieldRef();
  };

  const insertTextTab = () => {
    Editor.current.insertTabRef(tabWidth.value);
  };

  const insertReturn = () => {
    Editor.current.insertReturnRef();
  };
  const backspaceSimulate = () => {
    Editor.current.backspaceSimulateRef();
  };

  const importFromFile = (file) => {
    const reader = new FileReader();

    let ext = '';
    const parts = file.name.split('.');
    if (parts.length > 1) ext = parts.pop();

    if (ext === 'crossloadxplain') {
      reader.readAsText(file);

      reader.onload = function () {
        const result = JSON.parse(reader.result);
        console.log(result);
        painter.clear();
        history.current.clearHistory();
        setIsHistoryChange(true);

        // Moved state application to top to use with 2nd Screen messages.
        applyImportedState(result);
      };

      reader.onerror = function () {
        console.log(reader.error);
      };
    } else {
      setValidExt(false);
    }
  };

  const exportToFile = () => {
    // Moved generic saveData function to top to global scope to make it reusable for settings

    // Moved saved state to top, to use in 2nd screen presentation
    const resultFileExample = getStateToExport();

    const fileName = `${EXPORT_FILE_NAME}.crossloadxplain`;

    saveData(resultFileExample, fileName);

    setIsActualFileExported(true);
  };

  const focusEditor = () => {
    Editor.current.editorFocusRef();
  };
  const moveFocusToEnd = () => {
    const editableDiv = document.querySelector('.public-DraftEditor-content');
    editableDiv.removeAttribute('inputmode');
    Editor.current.moveFocusToEndRef();
  };

  useLayoutEffect(() => {
    const editorView = document.getElementsByClassName('DraftEditor-root')[0];
    editorView.addEventListener('click', Editor.current.editorFocusRef);
    // activeMode === ACTIVE_EDIT_MODE.TEXT
    if (activeMode === ACTIVE_EDIT_MODE.TEXT) {
      editorView.addEventListener('scroll', scrollEditorEvent);
    } else {
      drawingRef.current.addEventListener('scroll', scrollEditorEvent);
    }
    return () => {
      editorView.removeEventListener('scroll', scrollEditorEvent);
      painterRef.current.removeEventListener('scroll', scrollEditorEvent);
      drawingRef.current.removeEventListener('scroll', scrollEditorEvent);
      editorView.removeEventListener('click', Editor.current.editorFocusRef);
    };
  }, []);

  useLayoutEffect(() => {
    const editorView = document.querySelector('[data-contents="true"]');
    const editorBlock = document.querySelector('.editorBlock');
    /* setEditorHeight(
      editorView.offsetHeight > 560 ? editorView.offsetHeight + 100 : 560
    ); */

    setEditorHeight(
      editorView.offsetHeight < editorBlock.offsetHeight
        ? '100%'
        : editorView.offsetHeight + 100
    );
  }, [editorScroll]);

  //
  // Unsaved progress block
  //
  const [
    unsavedProgressModalVisible,
    setUnsavedProgressModalVisible,
  ] = useState(false);

  const closeUnsavedProgressModal = () => {
    setUnsavedProgressModalVisible(false);
  };
  const showUnsavedProgressModal = () => {
    setUnsavedProgressModalVisible(true);
  };

  const isRecordingActive = () => {
    return recordingStatus === 'recording';
  };

  // const isEditorContentUnsaved = () => {
  //   if (!isActualFileExported) {
  //     if (comments.length || drawings.length || textFields.length) {
  //       return true;
  //     }
  //     return false;
  //   }
  //   return false;
  // };

  useEffect(() => {
    setIsActualFileExported(false);
  }, [rawEditorState, textFields, comments, drawings]);

  useEffect(() => {
    const editor = !!Editor.current && Editor.current.getEditorRef();
    if (!editor) {
      return;
    }

    const detectUnsupportedAndroidKeyboard = (event) => {
      if (event.keyCode !== 229) {
        return;
      }

      setIsUnsupportedAndroidKeyboardErrorVisible(true);
      editor.removeEventListener('keydown', detectUnsupportedAndroidKeyboard);
    };
    const setIsEditorWasBlurred = () => setIsEditorJustBlurred(true);

    editor.addEventListener('blur', setIsEditorWasBlurred);
    editor.addEventListener('keydown', detectUnsupportedAndroidKeyboard);

    return () => {
      editor.removeEventListener('blur', setIsEditorWasBlurred);
      editor.removeEventListener('keydown', detectUnsupportedAndroidKeyboard);
    };
  }, [Editor.current && Editor.current.getEditorRef()]);

  useEffect(() => {
    if (isCustomBlockTextChanged(previousComments, comments)) {
      setIsCommentHasUnsavedHistoryChanges(true);
    }
  }, [comments]);

  useEffect(() => {
    if (isCustomBlockTextChanged(previousTextFields, textFields)) {
      setIsTextFieldHasUnsavedHistoryChanges(true);
    }
  }, [textFields]);

  useEffect(() => {
    setIsEditorJustBlurred(false);

    if (isHistoryChange) {
      setIsHistoryChange(false);
      return;
    }

    if (
      isEditorInlineStylesChanged(previousRawEditorState, rawEditorState) ||
      isEditorCustomBlockCountChanged(previousRawEditorState, rawEditorState) ||
      isEditorSpaceSymbolsAdded(previousRawEditorState, rawEditorState) ||
      isEditorTextRemoved(previousRawEditorState, rawEditorState) ||
      (isEditorJustBlurred && isEditorHasUnsavedChanges) ||
      isCustomBlockSpaceSymbolsAdded(previousTextFields, textFields) ||
      isCustomBlockSpaceSymbolsAdded(previousComments, comments) ||
      isCustomBlockCountChanged(previousTextFields, textFields) ||
      isCustomBlockCountChanged(previousComments, comments) ||
      isCustomBlockCountChanged(previousDrawings, drawings) ||
      isCustomBlockPositionChanged(previousTextFields, textFields) ||
      isCustomBlockPositionChanged(previousDrawings, drawings) ||
      (previousVisibleCommentId !== visibleCommentId &&
        !visibleCommentId &&
        isCommentHasUnsavedHistoryChanges) ||
      (previousTextFieldsFocus !== textFieldsFocus &&
        !textFieldsFocus &&
        isTextFieldHasUnsavedHistoryChanges)
    ) {
      addHistoryIteration();

      // Mark current file version as new to show "You are closing this page" popup
      setIsActualFileExported(false);
    }
  }, [
    rawEditorState,
    textFields,
    comments,
    drawings,
    visibleCommentId,
    isEditorHasUnsavedChanges,
    isEditorJustBlurred,
  ]);

  useEffect(() => {
    if (!painterRef.current) {
      return;
    }

    let isPaintStarted = false;
    const setIsPainStarted = () => {
      isPaintStarted = true;
    };
    const handlePaintChange = () => {
      if (!isPaintStarted) {
        return;
      }
      painter.handleChangeXML();
      isPaintStarted = false;
    };

    if (window.PointerEvent) {
      painterRef.current.addEventListener('pointerdown', setIsPainStarted);
      window.addEventListener('pointerup', handlePaintChange);
      painterRef.current.addEventListener('pointerleave', handlePaintChange);
      painterRef.current.addEventListener('pointercancel', handlePaintChange);
    } else {
      painterRef.current.addEventListener('mousedown', setIsPainStarted);
      window.addEventListener('mouseup', handlePaintChange);
      painterRef.current.addEventListener('mouseleave', handlePaintChange);
      painterRef.current.el.addEventListener('mouseout', handlePaintChange);
    }

    if ('ontouchstart' in window) {
      painterRef.current.addEventListener('touchstart', setIsPainStarted);
      window.addEventListener('touchcancel', handlePaintChange);
      painterRef.current.addEventListener('touchend', handlePaintChange);
    }

    return () => {
      if (window.PointerEvent) {
        painterRef.current.removeEventListener('pointerdown', setIsPainStarted);
        window.removeEventListener('pointerup', handlePaintChange);
        painterRef.current.removeEventListener(
          'pointerleave',
          handlePaintChange
        );
        painterRef.current.removeEventListener(
          'pointercancel',
          handlePaintChange
        );
      } else {
        painterRef.current.removeEventListener('mousedown', setIsPainStarted);
        window.removeEventListener('mouseup', handlePaintChange);
        painterRef.current.removeEventListener('mouseleave', handlePaintChange);
        painterRef.current.el.removeEventListener(
          'mouseout',
          handlePaintChange
        );
      }

      if ('ontouchstart' in window) {
        painterRef.current.removeEventListener('touchstart', setIsPainStarted);
        window.removeEventListener('touchcancel', handlePaintChange);
        painterRef.current.removeEventListener('touchend', handlePaintChange);
      }
    };
  }, [painterRef.current, painter]);

  useLayoutEffect(() => {
    window.onbeforeunload = (ev) => {
      if (
        isRecordingActive() ||
        isPresentationActive ||
        !isActualFileExported
      ) {
        ev.preventDefault();

        setTimeout(() => {
          const hidePopup = () => {
            closeUnsavedProgressModal();
          };

          setTimeout(() => {
            window.removeEventListener('unload', hidePopup);
            showUnsavedProgressModal();
          }, 300);

          window.addEventListener('unload', hidePopup);
        }, 100);

        // showUnsavedProgressModal();
        ev.returnValue = 'Close?';
        return ev.returnValue;
      }
    };
  });

  //
  // End of Unsaved Progress block
  //

  const scrollUpArrow = useRef();
  const scrollDownArrow = useRef();
  const scrollIncrement = 25;

  // const scrollUp = () => {
  //   const draftEditorRoot = document.getElementsByClassName(
  //     'DraftEditor-root'
  //   )[0];
  //   draftEditorRoot.scrollBy(0, -scrollIncrement);
  // };
  // const scrollDown = () => {
  //   const draftEditorRoot = document.getElementsByClassName(
  //     'DraftEditor-root'
  //   )[0];
  //   draftEditorRoot.scrollBy(0, scrollIncrement);
  // };

  const scrollUpTimeout = useRef();
  const scrollUpInterval = useRef();

  const startScrollUp = () => {
    const draftEditorRoot = document.getElementsByClassName(
      'DraftEditor-root'
    )[0];
    draftEditorRoot.scrollBy(0, -scrollIncrement);

    scrollUpTimeout.current = setTimeout(() => {
      scrollUpInterval.current = setInterval(() => {
        draftEditorRoot.scrollBy(0, -scrollIncrement);
      }, 50);
    }, 500);
  };
  const endScrollUp = () => {
    if (scrollUpTimeout.current) {
      clearTimeout(scrollUpTimeout.current);
    }
    if (scrollUpInterval.current) {
      clearInterval(scrollUpInterval.current);
    }
  };

  const scrollDownTimeout = useRef();
  const scrollDownInterval = useRef();

  const startScrollDown = () => {
    const draftEditorRoot = document.getElementsByClassName(
      'DraftEditor-root'
    )[0];
    draftEditorRoot.scrollBy(0, scrollIncrement);

    scrollDownTimeout.current = setTimeout(() => {
      scrollDownInterval.current = setInterval(() => {
        draftEditorRoot.scrollBy(0, scrollIncrement);
      }, 50);
    }, 500);
  };
  const endScrollDown = () => {
    if (scrollDownTimeout.current) {
      clearTimeout(scrollDownTimeout.current);
    }
    if (scrollDownInterval.current) {
      clearInterval(scrollDownInterval.current);
    }
  };

  // Save settings in localstorage
  useEffect(() => {
    localStorage.setItem(
      'settings',
      JSON.stringify({
        penWidth,
        tabWidth,
        penTransparency,
        fontSize,
        lineHeight,
        language,
      })
    );
  }, [penWidth, tabWidth, penTransparency, fontSize, lineHeight, language]);

  let editorContainerStyles;
  /* eslint-disable */

  if (presentationMode) {
    editorContainerStyles = fillScreen(editorContainerRef.current);
  }
  if (isPresentationActive) {
    editorContainerStyles = {
      width: (editorContainerRef.current.offsetHeight / 9) * 16,
      flexGrow: 0,
    };
  }
  useEffect(() => {
    console.log('presentationMode', presentationMode);
  }, [presentationMode]);

  useEffect(() => {
    if (isMobile) {
      return;
    }
    function resizeBlockTo169AspectRatio() {
      const editorBlock = document.querySelector('.editorBlock');
      console.log(editorBlock.offsetWidth);
      console.log(`${(editorBlock.offsetWidth / 16) * 9}px`);
      editorBlock.style.minHeight = `${(editorBlock.offsetWidth / 16) * 9}px`;
      editorBlock.style.height = `${(editorBlock.offsetWidth / 16) * 9}px`;
      editorBlock.style.maxHeight = `${(editorBlock.offsetWidth / 16) * 9}px`;
      editorBlock.style.flexGrow = '0';
    }
    resizeBlockTo169AspectRatio();
    window.addEventListener('resize', resizeBlockTo169AspectRatio);
    return () =>
      window.removeEventListener('resize', resizeBlockTo169AspectRatio);
  }, []);

  /* eslint-disable */

  return (
    <HelpTextContext.Provider value={helpTextVisible}>
      <UnsavedProgressModal
        showUnsavedProgressModal={unsavedProgressModalVisible}
        handleCloseUnsavedProgressModal={closeUnsavedProgressModal}
        fileUnsaved={!isActualFileExported}
        recordingActive={isRecordingActive()}
        presentationActive={isPresentationActive}
        unsavedFileAction={exportToFile}
        ongoingRecordingAction={stopRecording}
        activePresentationAction={handleTerminate}
      />
      <Container
        className={`flex-grow-1 d-flex flex-column justify-content-around ${
          presentationMode ? 'presentation-mode' : ''
        }`}
        fluid
      >
        <div
          className="h-auto d-flex flex-wrap justify-content-lg-between align-items-center justify-content-around"
          style={{ minHeight: '100px', marginBottom: '10px' }}
        >
          <div className="my-2 text-center logoWrapper">
            <img width="100px" src={logo} alt={t('Crossload#xplain')} />
          </div>
          <div style={{ minWidth: '320px' }} className="my-2">
            <FileActions
              clearWorkspace={clearWorkSpace}
              insertClipboardText={insertClipboardText}
              exportToFile={exportToFile}
              importFromFile={importFromFile}
            />
          </div>
          <div style={{ minWidth: '100px' }} className="my-2">
            <History
              undo={undoAction}
              redo={redoAction}
              canUndo={history.current.canUndo()}
              canRedo={history.current.canRedo()}
            />
          </div>
          <div style={{ minWidth: '100px' }} className="my-2">
            <Record
              isPresentationSupported={isPresentationSupported}
              isVideoRecordingSupported={isVideoRecordingSupported}
              resumeRecording={resumeRecording}
              status={recordingStatus}
              pauseRecording={pauseRecording}
              startRecording={startRecording}
              stopRecording={stopRecording}
              availability={presentationAvailability}
              onPresentationClick={initPresentation}
              onPresentationClose={handleTerminate}
              isPresentationActive={isPresentationActive}
              pendingMediaBlobUrl={pendingMediaBlobUrl}
              resetPendingMediaBlobUrl={resetPendingMediaBlobUrl}
              presentationErrorText={presentationErrorText}
              clearPresentationErrorText={clearPresentationErrorText}
              recSoundActive={recSoundActive}
              setRecSoundActive={setRecSoundActive}
              saveRecordingMimeType={bestMimeType}
            />
          </div>
          <div style={{ minWidth: '100px' }} className="my-2">
            {/* Help and settings buttons component */}
            <SettingsContext.Provider
              value={{
                penWidthSettings: {
                  penWidth,
                  setPenWidth,
                  penWidthValues,
                },
                tabWidthSettings: {
                  tabWidthValues,
                  tabWidth,
                  setTabWidth,
                },
                penTransparencySettings: {
                  penTransparencyValues,
                  penTransparency,
                  setPenTransparency,
                  penTransparencyLabels,
                },
                fontSizeSettings: {
                  fontSizeValues,
                  fontSize,
                  setFontSize,
                  fontSizeLabels,
                },
                lineHeightSettings: {
                  lineHeightValues,
                  lineHeight,
                  setLineHeight,
                },
                languageSettings: {
                  languageValues,
                  language,
                  setLanguage,
                },
                exportSettings,
                importSettings,
              }}
            >
              <SettingsButtons
                handleResetSettings={handleResetSettings}
                handleToggleHelpText={toggleHelpText}
              />
            </SettingsContext.Provider>
          </div>
        </div>
        <div className="editorRow d-flex justify-content-between">
          <div
            ref={editorBlockRef}
            className={`editorBlock px-0 mb-2 ${
              presentationMode ? 'presentationMode' : ''
            }`}
            id="editor-container"
            ref={editorContainerRef}
            style={{
              ...editorContainerStyles,
              ...presentationEditorContainerStyles,
            }}
          >
            <div
              className="editorReadOnlyOverlay"
              style={{ display: presentationMode ? 'block' : 'none' }}
            />
            <div
              ref={drawingRef}
              className="drawingWrapper"
              style={{
                pointerEvents:
                  activeMode === ACTIVE_EDIT_MODE.PAINT ? null : 'none',
                zIndex: 4,
              }}
            >
              <div
                className="drawingItemsSpace"
                style={{
                  height: editorPlaceHeight,
                }}
              >
                {drawings.map((draw) => {
                  return (
                    <DrawingItem
                      onRemove={removeDrawingItem}
                      onChange={changeDrawingItem}
                      draw={draw}
                      key={draw.id}
                    />
                  );
                })}
              </div>
            </div>
            <div
              ref={painterRef}
              className="drawingWrapper"
              style={{
                zIndex: activeMode === ACTIVE_EDIT_MODE.PAINT ? 5 : 0,
              }}
            >
              <div
                ref={painter.workspace}
                className="paintSpace"
                style={{
                  height: editorPlaceHeight,
                }}
              />
            </div>
            <div
              className="position-relative d-flex"
              style={{
                top: `-${editorScroll}px`,
                height: '0px',
                zIndex: activeMode === ACTIVE_EDIT_MODE.TEXT ? 3 : 2,
              }}
            >
              {textFields.map((textField) => {
                return (
                  <TextField
                    editorContainerRef={editorContainerRef}
                    color={textField.color}
                    fontSize={fontSize && fontSize.value}
                    panelColor={colorPanelSelectedColor}
                    onChange={changeTextField}
                    onRemove={removeTextField}
                    content={textField.content}
                    id={textField.id}
                    key={textField.id}
                    position={textField.position}
                    setTextFieldFocus={setTextFieldFocus}
                    focusEditor={focusEditor}
                  />
                );
              })}
            </div>
            <div
              className="position-relative d-flex"
              style={{
                top: `-${editorScroll}px`,
                height: '0px',
                zIndex: !!visibleCommentId ? 6 : 2,
              }}
            >
              {comments.map((comment) => {
                return (
                  <CommentField
                    onChange={changeCommentary}
                    onRemoveClick={removeComment}
                    onHideClick={hideCommentary}
                    setVisibleCommentId={setVisibleCommentId}
                    dependencyText={rawEditorState}
                    content={comment.content}
                    id={comment.id}
                    key={comment.id}
                    visible={visibleCommentId === comment.id}
                  />
                );
              })}
            </div>

            <div
              className="DraftEditor-wrapper"
              style={{
                height: '100%',
                zIndex: activeMode === ACTIVE_EDIT_MODE.TEXT ? 1 : 0,
                // The following lines apply Setting to the parent component of TextEditor.
                // These settings are stored separately and do are not saved with text.
                // Thus they are not applied to the text in TextEditor directly, but to its parent component
                fontSize: String(fontSize.value), // Setting font size from Settings
                lineHeight: String(lineHeight.value), // Setting line-height size from Settings
              }}
            >
              <TextEditor
                editorScroll={editorScroll}
                editorContainerRef={editorContainerRef}
                editorScrollbarRef={editorScrollbarRef}
                setCurrentStyle={setCurrentStyle}
                setCurrentColor={setCurrentTextColor}
                setCurrentBackground={setCurrentBackground}
                onStateChange={setRawEditorStateAndTrackChanges}
                onIsSelectionCollapsedChange={setIsSelectionCollapsed}
                addTextField={addNewTextField}
                selectedColor={colorPanelSelectedColor}
                onCommentaryClick={showCommentary}
                editorReadOnly={
                  presentationMode || activeMode !== ACTIVE_EDIT_MODE.TEXT
                }
                isPresentationActive={isPresentationActive}
                ref={Editor}
              />

              <div className="scrollbarBg" ref={editorScrollbarRef} />
              <FaChevronUp
                ref={scrollUpArrow}
                // onClick={scrollUp}
                onMouseDown={startScrollUp}
                onMouseUp={endScrollUp}
                onMouseLeave={endScrollUp}
                className="scrollIcon scrollUp"
              />
              <FaChevronDown
                ref={scrollDownArrow}
                // onClick={scrollDown}
                onMouseDown={startScrollDown}
                onMouseUp={endScrollDown}
                onMouseLeave={endScrollDown}
                className="scrollIcon scrollDown"
              />
              {/* <div className="scrollPlaceholder" /> */}
            </div>
          </div>
          <div className="ml-2 ml-md-4">
            <ColorsPanel onColorClick={toggleColor} ref={ColorsPanelRef} />
          </div>
        </div>
        <div className="text-formatting-row h-auto d-flex justify-content-center align-items-center">
          <div className="position-relative flex-grow-1 mh-100">
            <TextFormatting
              textFieldsFocus={textFieldsFocus}
              commentFocus={commentFocus}
              activeMode={activeMode}
              changeEditMode={changeEditMode}
              onStyleButtonsClick={toggleTextStyle}
              onAddTextFieldClick={insertTextField}
              onColorButtonClick={toggleTextColor}
              onHighlightButtonClick={toggleTextHighlight}
              onClearButtonClick={resetStyles}
              onClearEditorButtonClick={resetEditor}
              onLineButtonClick={insertLine}
              insertTextTab={insertTextTab}
              insertReturn={insertReturn}
              onBackspaceButtonClick={backspaceSimulate}
              selectedColor={colorPanelSelectedColor}
              onAddCommentaryClick={addNewComment}
              currentStyle={currentStyle}
              currentColor={currentTextColor}
              currentBackground={currentBackground}
              onDonePainting={painter.handleChangeXML}
              isSelectionCollapsed={isSelectionCollapsed}
            />
          </div>
          <div className="mh-100 keyboardWrapper">
            <MobileView>
              <div className="d-flex align-items-center justify-content-center">
                <div className="my-2">
                  <ButtonHelpText
                    variant="warning"
                    className="btn-circle btn-sm btn-default"
                    helpText={t(`open\nkeyboard`)}
                    onClick={(e) => {
                      preventAll(e);
                      // Editor.current.editorFocusRef();
                      moveFocusToEnd();
                    }}
                  >
                    <img
                      src={OpenKeyboardIcon}
                      alt={t('open keyboard')}
                      className="iconSize"
                    />
                  </ButtonHelpText>
                </div>
              </div>
            </MobileView>
          </div>
        </div>
        <div
          className="fade modal-2nd-screen show"
          style={{
            display: presentationMode ? 'none' : 'none',
            backgroundColor: '#000',
            opacity: 0.9,
          }}
        />
        <ErrorExtension
          show={!isValidExt}
          onHide={() => {
            setValidExt(true);
          }}
        />
        <AndroidKeyboardErrorExtension
          show={isUnsupportedAndroidKeyboardErrorVisible}
          onHide={() => {
            setIsUnsupportedAndroidKeyboardErrorVisible(false);
          }}
        />
      </Container>
    </HelpTextContext.Provider>
  );
};

export default GeneralPage;
