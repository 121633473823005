import { useRef, useEffect } from 'react';

export const usePrevious = (value, initialValue = null) => {
  const ref = useRef(initialValue);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
