import React, { useEffect, useRef, useState } from 'react';
import { CustomPicker } from 'react-color';
import EditableInput from 'react-color/lib/components/common/EditableInput';
import Hue from 'react-color/lib/components/common/Hue';
import Saturation from 'react-color/lib/components/common/Saturation';
import { Button, Col, Row } from 'react-bootstrap';
import HuePointer from './Pointers/HuePointer';
import SaturationPointer from './Pointers/SaturationPointer';
import s from './ColorPalette.module.scss';
import SetDefaultColorsConfirm from '../Modals/SetDefaultColorsConfirm';
import settingsOK from '../../assets/Settings_OK.svg';
import { t } from '../../i18n';

const ColorPalette = ({
  hex,
  index,
  title,
  applySelectColor,
  onChange,
  setDefaultSetting,
  setPaletteRef,
  ...props
}) => {
  const [tempTitle, changeTitle] = useState(title);
  const [showDefaultColorsModal, changeShowModal] = useState(false);
  const paletteRef = useRef(null);

  useEffect(() => {
    setPaletteRef(paletteRef.current);
  }, []);

  const handleCloseModal = () => {
    changeShowModal(false);
  };
  const handleOpenModal = () => {
    changeShowModal(true);
  };

  const confirmDefaultColors = () => {
    setDefaultSetting(undefined, 0);
    handleCloseModal();
  };

  return (
    <>
      <div className={s.background} />
      <div className={s.wrapper} ref={paletteRef}>
        <div className="w-100 text-left">{`${t('Color')} ${index + 1}`}:</div>
        <div className="w-100 d-flex justify-content-between">
          <div className={s.saturationBlock}>
            <Saturation
              onChange={onChange}
              hex={hex}
              pointer={SaturationPointer}
              {...props}
            />
          </div>
          <div
            style={{
              width: '15px',
              height: '150px',
              position: 'relative',
            }}
          >
            <Hue
              onChange={onChange}
              hex={hex}
              pointer={HuePointer}
              direction="vertical"
              {...props}
            />
          </div>
        </div>
        <Row>
          <Col sm={4}>{t('Hex:')}</Col>
          <Col sm={8}>
            <EditableInput
              value={hex}
              onChange={onChange}
              style={{
                input: {
                  border: '0',
                  width: '100%',
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>{t('Name:')}</Col>
          <Col sm={8}>
            <input
              type="text"
              className={s.paletteInput}
              value={tempTitle}
              onChange={(e) => {
                changeTitle(e.currentTarget.value);
              }}
            />
          </Col>
        </Row>
        <Button
          onMouseDown={(e) => {
            e.preventDefault();
            applySelectColor(tempTitle);
          }}
          variant="warning"
          className="btn-circle btn-sm btn-default"
        >
          <img src={settingsOK} alt={t('OK')} className="iconSize" />
        </Button>
        <Button
          variant="link"
          className={`${s.resetButton} px-0 py-0`}
          onMouseDown={(e) => {
            e.preventDefault();
            handleOpenModal();
          }}
        >
          {t('Back to default')}
        </Button>
        {showDefaultColorsModal && (
          <SetDefaultColorsConfirm
            show={showDefaultColorsModal}
            onHide={handleCloseModal}
            confirmAction={confirmDefaultColors}
          />
        )}
      </div>
    </>
  );
};

export default CustomPicker(ColorPalette);
