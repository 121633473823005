import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import style from '../ModalsStyle.module.scss';
// import styleLocal from './PresentationErrorModal.module.scss';
import { t } from '../../../i18n';
import StartRecordIcon from '../../../assets/Record_StartRecord.svg';

const PresentationErrorModal = ({ onHide, show, presentationErrorText }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={style.defaultModal}
      centered
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-space-around">
          <div className="btn-circle btn-sm btn-default">
            <img
              src={StartRecordIcon}
              alt={t('recording')}
              className="iconSize"
            />
          </div>
          <span className={style.modalText}>{presentationErrorText}</span>
          <div className={style.modalButtons}>
            <Button
              variant="warning"
              className="btn-square btn-sm btn-default"
              onClick={onHide}
            >
              {t('Close').toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PresentationErrorModal;
