import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import style from '../ModalsStyle.module.scss';
// import styleLocal from './PresentationErrorModal.module.scss';
import { t } from '../../../i18n';
import StartRecordIcon from '../../../assets/Record_StartRecord.svg';

const SaveRecordingModal = ({ onHide, show, href, saveRecordingMimeType }) => {
  const mimeTypeToExtension = (mimeType) => {
    switch (mimeType) {
      case 'video/mp4':
        return 'mp4';
      case 'video/webm':
        return 'webm';
      case 'video/x-matroska':
        return 'mkv';
      default:
        return 'video';
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={style.defaultModal}
      centered
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-space-around">
          <div className="btn-circle btn-sm btn-default">
            <img src={StartRecordIcon} alt={t('record')} className="iconSize" />
          </div>
          <span className={style.modalText}>{t('Save recording?')}</span>
          <div className={style.modalButtons}>
            <Button
              variant="warning"
              className="btn-square btn-sm btn-default"
              href={href}
              download={`${t('my_recording')}.${mimeTypeToExtension(
                saveRecordingMimeType
              )}`}
              onClick={onHide}
            >
              {t('Yes').toUpperCase()}
            </Button>
            <Button
              variant="primary"
              onClick={onHide}
              className="btn-square btn-sm btn-secondary"
            >
              {t('Cancel').toUpperCase()}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SaveRecordingModal;
