import React, { useContext } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import FileButton from 'react-file-button';
import Undo from '../../../assets/Undo.svg';
import style from './SettingsModal.module.scss';
import { SettingsContext } from '../../../contexts/SettingsContext';
import BulletSelectorWrapper from './BuletSelectorWrapper';
import settingsOrangeIcon from '../../../assets/SettingsOrange.svg';
import settingsPenLineWidthIcon from '../../../assets/Settings_PenLineWidth.svg';
import settingsFontSizeIcon from '../../../assets/Settings_FontSize.svg';
import settingsTabSizeIcon from '../../../assets/Settings_TabSize.svg';
import settingsLineSpacing from '../../../assets/Settings_LineSpacing.svg';
import settingsPenTransparencyIcon from '../../../assets/Settings_LineTransparency.svg';
import settingsLanguageIcon from '../../../assets/Settings_Language.svg';
import RadioSelectorWrapper from './RadioSelectorWrapper';
import fileSaveIcon from '../../../assets/File_Save.svg';
import fileOpenIcon from '../../../assets/File_Open.svg';
import i18n, { t } from '../../../i18n';

// eslint-disable-next-line react/prop-types
const SettingsModal = ({
  showSettingsModal,
  handleCloseSettingsModal,
  handleResetSettings,
}) => {
  // Destructuring context
  const {
    penWidthSettings,
    tabWidthSettings,
    penTransparencySettings,
    fontSizeSettings,
    lineHeightSettings,
    languageSettings,
    exportSettings,
    importSettings,
  } = useContext(SettingsContext);

  // Destructuring settings
  const { penWidth, setPenWidth, penWidthValues } = penWidthSettings;
  const { tabWidth, setTabWidth, tabWidthValues } = tabWidthSettings;
  const {
    penTransparency,
    setPenTransparency,
    penTransparencyValues,
    penTransparencyLabels,
  } = penTransparencySettings;
  const {
    fontSizeValues,
    fontSize,
    setFontSize,
    fontSizeLabels,
  } = fontSizeSettings;
  const { lineHeightValues, lineHeight, setLineHeight } = lineHeightSettings;
  const { languageValues, language, setLanguage } = languageSettings;

  const setCurrentLanguage = (data) => {
    i18n.changeLanguage(data.value);
    setLanguage(data);
  };

  return (
    <Modal
      show={showSettingsModal}
      onHide={handleCloseSettingsModal}
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={style.settingsModal}
      centered
    >
      <Modal.Body>
        <i className={style.closeIcon} onClick={handleCloseSettingsModal}>
          Close
        </i>
        <div className="d-flex flex-column align-items-center justify-content-space-around">
          <div className="d-flex align-items-center justify-content-space-around mt-3">
            <div className="btn-circle btn-md btn-default">
              <img
                style={{ transform: 'scale(1.5)' }}
                src={settingsOrangeIcon}
                alt={t('settings')}
                className="iconSize"
              />
            </div>
            <h5 style={{ marginLeft: '0.5rem', marginTop: '0.2rem' }}>
              {t('SETTINGS')}
            </h5>
          </div>
        </div>

        <Row style={{ marginTop: '2.5rem' }}>
          <Col sm={6}>
            <div className={style.innerColumn}>
              <BulletSelectorWrapper
                icon={settingsFontSizeIcon}
                text={t('Font size')}
                values={fontSizeValues}
                labels={fontSizeLabels}
                activeIndexAndValue={fontSize}
                setActiveIndexAndValue={setFontSize}
              />
              <BulletSelectorWrapper
                icon={settingsPenLineWidthIcon}
                text={t('Pen line width')}
                values={penWidthValues}
                labels={penWidthValues}
                activeIndexAndValue={penWidth}
                setActiveIndexAndValue={setPenWidth}
              />
              <BulletSelectorWrapper
                icon={settingsTabSizeIcon}
                text={t('Tab Size')}
                values={tabWidthValues}
                labels={tabWidthValues}
                activeIndexAndValue={tabWidth}
                setActiveIndexAndValue={setTabWidth}
              />
            </div>
          </Col>
          <Col sm={6}>
            <div className={style.innerColumn}>
              <BulletSelectorWrapper
                icon={settingsLineSpacing}
                text={t('Line spacing')}
                values={lineHeightValues}
                labels={lineHeightValues}
                activeIndexAndValue={lineHeight}
                setActiveIndexAndValue={setLineHeight}
              />
              <BulletSelectorWrapper
                icon={settingsPenTransparencyIcon}
                text={t('Pen transparency')}
                values={penTransparencyValues}
                labels={penTransparencyLabels}
                activeIndexAndValue={penTransparency}
                setActiveIndexAndValue={setPenTransparency}
              />
              <RadioSelectorWrapper
                icon={settingsLanguageIcon}
                text={t('Language')}
                values={languageValues}
                activeIndexAndValue={language}
                setActiveIndexAndValue={setCurrentLanguage}
              />
            </div>
          </Col>
        </Row>

        {/* <div className={style.modalButtons}> */}
        {/*  <Button */}
        {/*    variant="primary" */}
        {/*    className={`${style.saveButton} btn-square btn-sm btn-secondary`} */}
        {/*    title="Restore default settings" */}
        {/*    onClick={(e) => { */}
        {/*      e.preventDefault(); */}
        {/*      handleResetSettings(); */}
        {/*    }} */}
        {/*  > */}
        {/*    Restore default settings */}
        {/*  </Button> */}
        {/* </div> */}

        <div className="text-center mt-2">
          {t('Load, Reset & Save all settings')}
        </div>

        <div className="d-flex justify-content-center mt-3">
          <div>
            <FileButton
              title={t('Load settings')}
              className={`${style.fileButton}`}
              onChange={(e) => {
                if (e.target.files[0]) {
                  importSettings(e.target.files[0]);
                }
              }}
              renderButton={() => {
                return (
                  <Button
                    variant="warning"
                    size="sm"
                    className="btn-circle btn-sm btn-default"
                  >
                    <img
                      src={fileOpenIcon}
                      alt={t('import settings')}
                      className="iconSize"
                    />
                  </Button>
                );
              }}
            />
          </div>
          <Button
            variant="warning"
            size="sm"
            className="btn-circle btn-sm btn-default mx-2"
            title={t('Reset settings')}
            onClick={(e) => {
              e.preventDefault();
              handleResetSettings();
            }}
          >
            <img src={Undo} alt={t('reset settings')} className="iconSize" />
          </Button>
          <Button
            variant="warning"
            size="sm"
            className="btn-circle btn-sm btn-default"
            title={t('Export settings')}
            onClick={(e) => {
              e.preventDefault();
              exportSettings();
            }}
          >
            <img
              src={fileSaveIcon}
              alt={t('export settings')}
              className="iconSize"
            />
          </Button>
        </div>

        <div className="text-center mt-4">
          <a
            href="https://crossload.org/xplain/about"
            target="_blank"
            rel="noreferrer"
            className={style.link}
          >
            {t('About')}
          </a>
          <span> | </span>
          <a
            // href="http://crossload.org/spenden"
            href="https://crossload.org/xplain/about"
            target="_blank"
            rel="noreferrer"
            className={style.link}
          >
            {t('Donations')}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SettingsModal;
