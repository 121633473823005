import React from 'react';
import style from './ActionListItem.module.scss';

const ActionListItem = (props) => {
  const { icon, label, action, visible } = props;

  return (
    <div
      style={{ cursor: 'pointer', display: visible ? 'flex' : 'none' }}
      className={`${style.unsavedActionWrapper} align-items-center my-2`}
      onClick={action}
    >
      <div className={`${style.iconWrapper} btn-circle btn-sm btn-default`}>
        {/* <img src={icon} alt={' '} className="iconSize" /> */}
        <div
          className={`${style.divBgIcon} iconSize`}
          style={{
            backgroundImage: `url(${icon})`,
          }}
        />
      </div>
      <div className="ml-3">{label}</div>
    </div>
  );
};

export default ActionListItem;
